import { useState } from 'react';

import InputField from 'components/form/InputField';
import { useAppContext } from './AppContext';

export const LocationAutocompleteField = (props: any) => {
    const ctx = useAppContext();
    const [locationOptions, setLocationOptions] = useState([]);
    const updateLocation = async (event: any) => {
        const query = event.query;
        const res: any = await (await ctx.publicApiCall(`/search/autocomplete?query=${query}`)).json();
        setLocationOptions(res.predictions.map((p: any) => ({
            description: p.description,
            id: p.place_id
        })));
    };
    return (
        <InputField autocompleteField='description' type='autocomplete' suggestions={locationOptions} completeMethod={updateLocation} {...props} />
    );
}