import { FC } from 'react';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { MarginContainer } from 'components/layout/Container';
import { Document, useDocument } from 'context/DocumentContext';
import { useDownloadURL, useFileInfo } from 'context/StorageContext';
import { pastDateToRTF } from 'util/date/DateUtil';
import { formatBytes } from 'util/file/FIleUtil';
import { calculateFileIcon, getFileExtension } from 'components/buttons/download/FileDownloadButton';
import { useAuth } from 'context/AuthContext';
import { useHistory } from 'react-router';
import { SpeedDial } from 'primereact/speeddial';

interface FileInfoProps {
    document?: Document,
}

export const FileInfoCard: FC<FileInfoProps> = ({ document }) => {
    const displayFile = (document?.file || document?.files![0].path) ?? '';
    const fileInfoDetails = useFileInfo(displayFile);
    const fileInfo = fileInfoDetails.fileInfo;
    const loading = fileInfoDetails.loading || !fileInfo;
    const fileIcon = displayFile && <>
        <i className={calculateFileIcon(displayFile)} style={{ fontSize: '1.5rem' }} />
        <span style={{ fontSize: '0.8rem' }}>{getFileExtension(displayFile)}</span>
    </>;
    const fileTypeBadge = (
        <div className='p-d-flex p-flex-column p-jc-center p-ai-center' style={{ width: 'fit-content' }}>
            {fileIcon}
        </div>
    );
    if (loading) {
        return (
            <Card title='File Info' footer={fileTypeBadge}>
                <p> Loading </p>
                <p>{formatBytes(fileInfo?.size)}</p>
            </Card>
        );
    }
    return (
        <Card title='File Info' subTitle={fileInfo?.name} footer={fileTypeBadge}>
            {fileInfo?.timeCreated && <p>Created {pastDateToRTF(new Date(fileInfo?.timeCreated!))}</p>}
            {fileInfo?.updated && <p>Last Updated {pastDateToRTF(new Date(fileInfo?.timeCreated!))}</p>}
            {/* <p>{}</p> */}
            <p>{formatBytes(fileInfo?.size)}</p>
        </Card>
    );
}

interface DocumentViewProps {
    documentId: string
}
const Page: FC<DocumentViewProps> = ({
    documentId
}) => {
    const document = useDocument(documentId);
    const displayFile = (document?.file || document?.files![0].path) ?? '';
    const fileURLDetails = useDownloadURL(displayFile);
    const fileInfoDetails = useFileInfo(displayFile);
    const fileURL = fileURLDetails.fileURL;
    const fileInfo = fileInfoDetails.fileInfo;
    const loading = fileURLDetails.loading || fileInfoDetails.loading || !fileURL || !fileInfo;
    // breadcrumb options
    const breadcrumbHome = { icon: 'pi pi-search', url: '/document/search' };
    const breadcrumbModel = [{ label: document?.name }];
    const fileDownload = displayFile && (
        <a href={fileURL} style={{ textDecoration: 'none', color: 'unset' }}>
            <Button icon='pi pi-download' label='Download' />
        </a>
    );
    const fileUpload = document?.dueDate && (
        <Button icon='pi pi-upload' label='Upload' />
    );
    // speeddial
    const { isAdmin } = useAuth();
    const history = useHistory();
    const navigateOCL = (path: string) => () => { history.push(path); };
    // speeddial
    const speedDialModel = [];
    if (isAdmin) {
        speedDialModel.push(
            { label: 'Edit', icon: 'pi pi-pencil', command: navigateOCL(`/document/${documentId}/edit`) },
            { label: 'Delete', icon: 'pi pi-trash', command: navigateOCL(`/document/${documentId}/delete`) },
        );
    }
    return <>
        <BreadCrumb model={breadcrumbModel} home={breadcrumbHome} />
        <MarginContainer loading={loading}>
            <div className='p-grid'>
                <div className='p-col-12 p-md-8'>
                    <Card title={document?.name} subTitle={document?.type}
                        footer={fileDownload}>
                        <p>{document?.description}</p>
                    </Card>
                    {document?.acceptUserSubmissions && (
                        <Card title='Submit Document' className='p-mt-3' footer={fileUpload}>
                            <p>Submission Instructions: {document?.directions}</p>
                        </Card>
                    )}
                </div>
                <div className='p-col-12 p-md-4'>
                    <FileInfoCard document={document} />
                </div>
            </div>
        </MarginContainer>
        {isAdmin && <>
            <SpeedDial direction='left' model={speedDialModel}
                style={{ position: 'fixed', bottom: '2rem', right: '2rem' }} className='speeddial-left' />
        </>}
    </>;
}

export default Page;