import { FC, useState } from 'react';

import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';

import { useDocuments, Document } from 'context/DocumentContext';
import DocumentCard from 'components/dataview/document/DocumentCard';
import { dateInPast } from 'util/date/DateUtil';
import { useListLabels } from 'context/ConfigurationContext';
import { useAuth } from 'context/AuthContext';
import { useBrother } from 'context/BrotherContext';

const MyDocumentsDashboardCard: FC = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const docs: Document[] = useDocuments();
    const { userRole, user } = useAuth();
    const roleLookup = useListLabels('roles');
    const myRole = roleLookup[userRole!];
    const visibleToMyRole = docs.filter(doc => doc.accessType === 'role' && doc.access === 'admin');
    const keyDocuments = docs.filter(doc => doc.category === 'key')

    const tabs = [];
    tabs.push(
        <TabPanel header={`Key Documents`}>
            {keyDocuments.length > 0 ?
                keyDocuments
                    .filter((_, idx) => idx <= 3)
                    .map(d => <DocumentCard key={d.id} {...d} />)
                : <p>No Documents</p>}
        </TabPanel>
    );
    if (userRole !== null) {
        tabs.push(
            <TabPanel header={`Visible to ${myRole}`}>
                {visibleToMyRole.length > 0 ?
                    visibleToMyRole
                        .filter((_, idx) => idx <= 3)
                        .map(d => <DocumentCard key={d.id} {...d} />)
                    : <p>No Documents</p>}
            </TabPanel>
        );
    }
    return <>
        <Card title='My Documents'>
            <TabView activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}>
                {tabs}
            </TabView>
        </Card>
    </>;
}

export default MyDocumentsDashboardCard;