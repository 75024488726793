import { FC } from 'react';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { MarginContainer } from 'components/layout/Container';
import { useBrother } from 'context/BrotherContext';
import { useListLabels } from 'context/ConfigurationContext';
import { SpeedDial } from 'primereact/speeddial';
import { useHistory } from 'react-router';
import { useAuth } from 'context/AuthContext';

interface BrotherViewProps {
    brotherId: string
}
const Page: FC<BrotherViewProps> = ({
    brotherId
}) => {
    const { isAdmin, userRole, user } = useAuth();
    const { brother, loading } = useBrother(brotherId);
    const locations = useListLabels('locations');
    const realName = brother?.realName ?? '';
    const brotherName = brother?.brotherName ?? realName;
    const brotherLocationCrossed = brother?.locationCrossed && locations[brother!.locationCrossed];
    const brotherImage = brother?.profileURL ? (
        <div className='p-shadow-2 p-mb-3' style={{
            height: '20em',
            width: '100%',
            borderRadius: '3px',
            overflow: 'hidden',
        }}>
            <img alt={brotherName} src={brother?.profileURL} style={{
                height: '100%', width: '100%', objectFit: 'cover', objectPosition: '50% 40%'
            }} />
        </div>
    ) : undefined;
    // speeddial options
    const history = useHistory();
    const navigateOCL = (url: string, external = false) => () => {
        if(external) {
            window.open(url, '_blank');
        } else {
            history.push(url);
        }
    };
    const speedDialModel = [];
    let showSpeedDial = false;
    if(user.uid === brotherId || isAdmin) {
        showSpeedDial = true;
        speedDialModel.push(
            { label: 'Update', icon: 'pi pi-pencil', command: navigateOCL(`/brother/${brotherId}/edit`) }
        );
    }
    if(userRole === 'admin') {
        showSpeedDial = true;
        speedDialModel.push(
            { label: 'Add to Group', icon: 'pi pi-users', command: () => { } },
            { label: 'Delete', icon: 'pi pi-trash', command: () => { } },
        );
    }
    const showSocialLinks = brother?.instagram || brother?.linkedIn || brother?.indeed || brother?.facebook || brother?.website || brother?.mattermost;
    // breadcrumb options
    const breadcrumbHome = { icon: 'pi pi-search', url: '/brother/search' };
    const breadcrumbModel = [{ label: realName }];
    return <>
        <BreadCrumb model={breadcrumbModel} home={breadcrumbHome} />
        <MarginContainer loading={loading}>
            <div className='p-grid'>
                <div className='p-col-12 p-md-4'>
                    {brotherImage}
                    <Card title={brotherName} subTitle={realName}>
                        {brother?.semesterCrossed && <p>Crossed {brother?.semesterCrossed}, {brotherLocationCrossed}</p>}
                        {brother?.inUndergrad ? (
                            <p>Expected to graduate {brother?.graduationSemester}</p>
                        ) : <>
                            <p>Graduated {brother?.graduationSemester}</p>
                            {(brother?.employer&&brother?.profession) && <p>{brother.profession} at {brother.employer}</p>}
                            {(brother?.employer&&!brother?.profession) && <p>Works at {brother.employer}</p>}
                            {(!brother?.employer&&brother?.profession) && <p>Works as a {brother.profession}</p>}
                        </>}
                        {brother?.location && <p>Currently in {brother?.location?.description}</p>}
                    </Card>
                </div>
                <div className='p-col-12 p-md-8'>
                    <Card title='About Me' className='p-mb-3'>
                        {brother?.aboutMe}
                    </Card>
                    {showSocialLinks && <Card title='Social Links'>
                        <div className='p-d-flex p-flex-wrap p-jc-center'>
                            {brother?.instagram && <Button icon='pi pi-external-link' label='Instagram' className='p-button-text p-button-plain p-mr-1 p-mb-1' onClick={navigateOCL(brother?.instagram, true)} />}
                            {brother?.linkedIn && <Button icon='pi pi-external-link' label='LinkedIn' className='p-button-text p-button-plain p-mr-1 p-mb-1' onClick={navigateOCL(brother?.linkedIn, true)} />}
                            {brother?.indeed && <Button icon='pi pi-external-link' label='Indeed' className='p-button-text p-button-plain p-mr-1 p-mb-1' onClick={navigateOCL(brother?.indeed, true)} />}
                            {brother?.facebook && <Button icon='pi pi-external-link' label='Facebook' className='p-button-text p-button-plain p-mr-1 p-mb-1' onClick={navigateOCL(brother?.facebook, true)} />}
                            {brother?.website && <Button icon='pi pi-external-link' label='Website' className='p-button-text p-button-plain p-mr-1 p-mb-1' onClick={navigateOCL(brother?.website, true)} />}
                            {brother?.mattermost && <Button icon='pi pi-comment' label='Mattermost' className='p-button-text p-button-plain p-mr-1 p-mb-1' onClick={navigateOCL(brother?.mattermost, true)} />}
                        </div>
                    </Card>}
                </div>
            </div>
        </MarginContainer>
        {showSpeedDial && <>
            {/* <Tooltip target=".speeddial-left .p-speeddial-action" /> */}
            <SpeedDial direction='up' model={speedDialModel} radius={100}
                style={{ position: 'fixed', bottom: '2rem', right: '2rem' }} className='speeddial-left' />
        </>}
    </>;
}

export default Page;