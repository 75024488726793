import { action, makeObservable, observable } from 'mobx';
import Cookies from 'js-cookie';

import { firebaseApp } from 'config/firebase';
import FirestoreCollectionService from 'services/FirestoreCollectionService';
import { AuthenticationError } from 'services/Errors';

const serviceConfig = {
    db: firebaseApp.firestore(),
    collectionName: 'mattermost',
    objectSortColumn: 'accessToken',
    objectSortDirection: 'asc',
};
const storeConfig = {
    store: false,
    objectCollection: false,
    idToken: observable,
    mattermostUrl: observable,
    mattermostUrlRoot: observable,
    loginCookieExists: observable,
    updateLoginCookieFlag: action,
    initialize: action,
};

class MattermostStore extends FirestoreCollectionService {
    store = null;
    idToken = null;
    userId = null;
    mattermostUrl = null;
    mattermostUrlRoot = null;
    loginCookieExists = false;

    constructor(store) {
        super(serviceConfig);
        makeObservable(this, storeConfig);
        this.store = store;
    }

    objectCollection = (view='default') => {
        let collectionRef = this.db.collection(this.collectionName);
        return collectionRef;
    };

    initialize = async () => {
        const userId = this.store.auth.user.uid;
        const mattermostUrl = this.store.config.mattermostUrl;
        if (mattermostUrl) {
            this.mattermostUrl = mattermostUrl;
            this.mattermostUrlRoot = mattermostUrl.match(/\.[^.]{3,}\.[^.]{3,}$/)[0];
        }
        let config = {};
        try {
            config = await this.getObject(userId, true);
        } catch(err) {}
        if (config.idToken) {
            this.idToken = config.idToken;
        }
        if (config.userId) {
            this.userId = config.userId;
        }
        this.updateLoginCookieFlag();
    };

    setLoginCookie = () => {
        const cookieProps = {
            expires: 30,
            domain: this.mattermostUrlRoot,
            path: '/',
            secure: true,
        };
        Cookies.set('MMUSERID', this.userId, cookieProps);
        Cookies.set('MMAUTHTOKEN', this.idToken, cookieProps);
        this.updateLoginCookieFlag();
    };

    clearLoginCookie = () => {
        const cookieProps = {
            expires: 30,
            domain: this.mattermostUrlRoot,
            path: '/',
            secure: true,
        };
        Cookies.remove('MMUSERID', cookieProps);
        Cookies.remove('MMAUTHTOKEN', cookieProps);
        this.updateLoginCookieFlag();
    };

    updateLoginCookieFlag = () => {
        this.loginCookieExists = !!Cookies.get('MMUSERID') && !!Cookies.get('MMAUTHTOKEN');
    };

    /**
     * Get Mattermost information about a particular brother. Returns an empty object if no details have been stored yet.
     * @param {*} brotherId Firebase Auth User Id
     * @param {*} useCache Use Firestore Cache
     */
    getDetails = async (brotherId, useCache=true) => {
        let details = {};
        try {
            details = await this.getObject(brotherId, useCache);
        } catch(err) {
            console.log(err);
        }
        return details;
    }

    apiCall = (endpoint, options = {}) => {
        const url = `${this.store.config.mattermostUrl}/api/v4${endpoint}`;
        if (!this.idToken) {
            throw new AuthenticationError(this.serviceName, 'no-token', `A secure Mattermost request was attempted but no identity tokens could be accessed by ${this.serviceName}.`)
        }
        const headers = options.headers || {};
        headers['Authorization'] = `Bearer ${this.idToken}`;
        options.headers = headers;
        return fetch(url, options);
    };
}

export default MattermostStore;