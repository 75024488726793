import { useMemo, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';

import { DataViewLayoutOptions, DataViewLayoutType, DataViewSortOrderType } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { Hit } from '@algolia/client-search'

import { useAlgoliaIndex } from 'hooks/search/AlgoliaHooks';

function getOrDefault(params: URLSearchParams, key: string, defaultValue: string = ''): string {
    if (params.has(key)) {
        const value = params.get(key);
        if (value !== null) {
            return value;
        }
    }
    return defaultValue;
}

function setOrDeleteIfNull(params: URLSearchParams, key: string, value: any): void {
    if (value) {
        params.set(key, value);
    } else if (params.has(key)) {
        params.delete(key);
    }
}

export function useSearchParams() {
    const history = useHistory();
    const searchString = history.location.search;
    const searchParams = useMemo(() => new URLSearchParams(searchString), [searchString]);
    return { searchParams, searchString };
}

type SearchFilter = { label: string, key: string, displayValue?: string, value: string, reset: () => void };
type SearchSortOption = { label: string, value: string };
type SearchResultHitProps = { objectID: string };

export function useSearch<SearchResultProps extends SearchResultHitProps>(
    searchIndexName: string,
    SearchResultComponent: React.FC<SearchResultProps & { layout: DataViewLayoutType }>,
    sortOptions?: SearchSortOption[],
    filters?: SearchFilter[]
) {
    const history = useHistory();
    const searchString = history.location.search;
    const searchParams = useMemo(() => new URLSearchParams(searchString), [searchString]);
    // we have internal raw values and "applied" values
    // query
    const defaultQuery = getOrDefault(searchParams, 'query', '');
    const queryStates = {
        immediate: useState<string>(defaultQuery),
        applied: useState<string>(defaultQuery),
    };
    // sorting
    const sortStates = {
        order: useState<DataViewSortOrderType>(-1),
        field: useState<string>(),
        key: useState<string>(),
    };
    // results, filters, and layout
    const [results, setResults] = useState<SearchResultProps[]>([]);
    const [layout, setLayout] = useState<DataViewLayoutType>('list');
    const [appliedFilters, setAppliedFilters] = useState<SearchFilter[]>([]);
    // loading and index
    const [loading, setLoading] = useState(false);
    const searchIndex = useAlgoliaIndex(searchIndexName);
    // sorting
    const onSortChange = (event: any) => {
        const value = event.value;
        const [, setSortOrder] = sortStates.order;
        const [, setSortField] = sortStates.field;
        const [, setSortKey] = sortStates.key;
        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    };
    const {
        immediate: [, setImmediateQuery],
        applied: [appliedQuery, setAppliedQuery]
    } = queryStates;
    const resetQuery = useCallback(() => {
        setAppliedQuery('');
        setImmediateQuery('');
    }, [setAppliedQuery, setImmediateQuery]);
    const search = useCallback(() => {
        (async () => {
            setLoading(true);
            setOrDeleteIfNull(searchParams, 'query', appliedQuery);
            // apply search filters to url
            filters?.forEach(({ key, value }) => setOrDeleteIfNull(searchParams, key, value));
            const newSearchString = `?${searchParams.toString()}`;
            if (searchString !== newSearchString) {
                history.push(newSearchString);
            }
            // set up search options
            let options: any = {};
            if (filters && filters.length > 0) {
                // encode search filters for api
                const activeFilters = filters!.filter(({ value }) => !!value);
                options.filters = activeFilters
                    .map(({ key, value }) => `${key}:${value}`).join('&');
                if (appliedQuery) {
                    activeFilters.push({
                        label: 'Search', key: 'query',
                        value: appliedQuery, displayValue: `"${appliedQuery}"`,
                        reset: resetQuery
                    });
                }
                setAppliedFilters(activeFilters);
            }
            // search
            const res = await searchIndex.search(appliedQuery, options);
            setResults(res.hits as Hit<SearchResultProps>[]);
            // setPageCount(res.nbPages);
            setLoading(false);
        })();
    }, [searchIndex, history, searchParams, appliedQuery, searchString, filters, setAppliedFilters, resetQuery]);
    useEffect(() => {
        // search on component load
        search();
    }, [search]);
    const [sortKey,] = sortStates.key;
    const itemTemplate = (item: SearchResultProps, layout: DataViewLayoutType) => {
        if (!item) return;
        return <SearchResultComponent {...item} layout={layout} />;
    };
    const searchResultsHeader = (
        <div className='p-d-flex p-flex-wrap p-jc-between'>
            <Dropdown options={sortOptions} value={sortKey} placeholder='Sort By' onChange={onSortChange} />
            <DataViewLayoutOptions layout={layout} onChange={e => setLayout(e.value as DataViewLayoutType)} />
        </div>
    );
    return {
        searchResultsHeader, itemTemplate,
        queryStates, sortStates, appliedFilters,
        results, layout, loading, search,
    };
};