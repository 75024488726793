import { FC, MouseEventHandler, useRef } from 'react';

import { Link } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';

import { AppContext, withContext } from 'context/AppContext';
import UserAvatar from './UserAvatar';

import logo from 'logo.png';

export const menuHeight = 40;

const menuModel = [
    {
        label: 'Brothers',
        icon: 'pi pi-fw pi-search',
        url: '/brother/search',
    },
    {
        label: 'Documents',
        icon: 'pi pi-fw pi-file',
        url: '/document/search',
    },
    {
        label: 'Community',
        icon: 'pi pi-fw pi-comment',
        url: '/community',
    },
    // {
    //     label: 'Lesson Plans',
    //     icon: 'pi pi-fw pi-book',
    //     url: '/lessons',
    // },
    {
        label: 'Admin',
        icon: 'pi pi-fw pi-cog',
        url: '/admin',
        adminOnly: true,
    }
];

interface MenuItemProps {
    target: string,
    url: string,
    label: string,
    onClick: MouseEventHandler,
}
interface MenuItemOptions {
    className: string,
    iconClassName: string,
    labelClassName: string,
}
const menuItemTemplate = (item: MenuItemProps, options: MenuItemOptions) => (
    <Link className={options.className} target={item.target} to={item.url} onClick={item.onClick}>
        <span className={options.iconClassName} />
        <span className={options.labelClassName}>{item.label}</span>
    </Link>
);

interface AppMenuProps {
    context: AppContext
}
const AppMenu: FC<AppMenuProps> = ({ context }) => {
    const isAdmin = context.auth.isAdmin;
    const menu = useRef<any>(null);
    const modelSpa = menuModel
        //if menu item is for admins, hide when not an admin
        .filter(({ adminOnly }) => !adminOnly || (adminOnly && isAdmin))
        .map(m => ({
            template: menuItemTemplate,
            onClick: (event: Event) => {
                if (menu.current!.state.mobileActive) {
                    menu.current!.toggle(new Event(event.type, event));
                }
            },
            ...m,
        }));
    const start = (
        <Link to='/' style={{ textDecoration: 'none', color: 'unset' }}>
            <img src={logo} height={menuHeight} className='p-mr-2' alt='Go Home' />
        </Link>
    )
    const end = (
        <div className='p-d-flex p-ai-center'>
            <UserAvatar auth={context.auth} />
        </div>
    );
    return (
        <Menubar model={modelSpa} ref={menu}
            start={start} end={end}
            className='p-shadow-1' style={{ backgroundColor: 'var(--surface-0)' }} />
    );
}

export default withContext(AppMenu);