import { FC, useEffect, useState } from "react";
import { Form, Formik } from "formik";

import { Document, FileInformation, useNewDocument } from "context/DocumentContext";
import { useAppContext } from "context/AppContext";

interface DocumentFile {
    name: string,
    lastModified: number,
    lastModifiedDate: Date,
    size: number,
    type: string,
    arrayBuffer: any, slice: any,
    stream: any, text: any,
}

interface UploadTask {
    bytesTransferred: number,
    totalBytes: number,
}

export type DocumentCreateFormValidation = (values: Document) => object;
interface DocumentFormProps {
    validate?: DocumentCreateFormValidation
}

export const useDocumentCreateController = () => {
    const { storage } = useAppContext();
    const { doc, updateDoc, loading } = useNewDocument();
    const [ uploadProgress, setUploadProgress ] = useState<UploadTask[]>([]);
    const [ filePathList, setFilePathList ] = useState<FileInformation[]>([]);
    const uploadFiles = async (files: DocumentFile[]) => {
        const newProgress = [];
        const filePathList = [];
        console.log(`started upload file handler`);
        for(const file of files) {
            const filePath = `documents/${doc.id+'/' ?? ''}${file.name}`;
            console.log(`uploading file to ${filePath}`);
            const upload = await storage.uploadFile(filePath, file as Blob);
            filePathList.push({
                path: filePath,
                name: file.name,
                size: file.size,
                type: file.type,
            });
            // const uploadProgress = upload.bytesTransferred/upload.totalBytes;
            newProgress.push(upload);
        }
        setFilePathList(filePathList);
        setUploadProgress(newProgress);
    };
    useEffect(() => {
        (async () => {
            if(uploadProgress.length > 0) {
                const totalProgress = uploadProgress.reduce((acc, p) => acc+(p.bytesTransferred/p.totalBytes), 0)/uploadProgress.length;
                console.log(`file progress update: ${totalProgress}`);
                if(totalProgress >= 1) {
                    console.log(`upload complete! updating document`);
                    setUploadProgress([]);
                    await updateDoc({ ...doc, files: [...doc.files??[], ...filePathList] });
                    console.log(doc);
                    setFilePathList([]);
                }
            }
        })();
    }, [ uploadProgress, updateDoc, setUploadProgress, setFilePathList ]);
    const onSubmit = async (fields: any) => {
        await updateDoc(fields);
    };
    const NewDocumentForm: FC<DocumentFormProps> = ({ children, validate }) => {
        return (
            <Formik onSubmit={onSubmit} initialValues={doc} validate={validate}>
                <Form>
                    {children}
                </Form>
            </Formik>
        );
    };
    return {
        doc, updateDoc, uploadFiles, uploadProgress,
        loading, NewDocumentForm,
    };
}