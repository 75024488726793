import { Suspense, FC, ReactNode } from 'react';

import { observer } from 'mobx-react-lite';
import { ProgressSpinner } from 'primereact/progressspinner';

import AuthenticationStore from 'context/stores/AuthenticationStore';

export const LoadingSpinner = () => (
    <div className='p-d-flex p-jc-center p-ai-center' style={{ height: '100%' }}>
        <ProgressSpinner strokeWidth='4' />
    </div>
);

interface LoadingLayerProps {
    auth: AuthenticationStore,
    children: ReactNode,
}
const AppLoadingLayer: FC<LoadingLayerProps> = observer(({ auth, children }) => {
    const { isLoaded } = auth;
    // define a loading component that is shown on first load and in a Suspense
    const loading = <LoadingSpinner />;
    if (isLoaded) {
        return (
            <Suspense fallback={loading}>
                {children}
            </Suspense>
        );
    }
    return loading;
});

export default AppLoadingLayer;