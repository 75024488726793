import InputField from "components/form/InputField";
import { MarginContainer } from "components/layout/Container";
import { useAuth } from "context/AuthContext";
import { useList, useListLabels } from "context/ConfigurationContext";
import { Form, Formik } from "formik";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FC, useState } from "react";

const Page: FC = () => {
    const { userRole, actualUserRole, setUserRole, isAdmin } = useAuth();
    const rolesList = useList('roles');
    const rolesLookup = useListLabels('roles');
    const breadcrumbHome = { icon: 'pi pi-cog', url: '/admin' };
    const breadcrumbModel = [{ label: 'Roles' }];
    const [role, setRole] = useState(userRole);
    const initialValues = { role: role };
    const onSubmit = (values: any) => {
        if(values.role !== role) {
            //update app
            setUserRole(values.role);
            //update this page
            setRole(values.role);
            window.alert('Update successful');
        }
    };
    const currentRole = rolesLookup[role!!];
    const originalRole = rolesLookup[actualUserRole!!];
    return <>
        <BreadCrumb model={breadcrumbModel} home={breadcrumbHome} />
        <MarginContainer maxWidth='800px'>
            <Card title='Change Role'>
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    <Form>
                        <p>Your current role is: {currentRole}</p>
                        <p>Your default role is: {originalRole}</p>
                        <InputField type='dropdown' label='Role' name='role' options={rolesList} />
                        <div className='p-d-flex'>
                            <Button type='submit' label='Save' />
                        </div>
                    </Form>
                </Formik>
            </Card>
        </MarginContainer>
    </>;
}

export default Page;