import { FC, useContext, useState } from 'react';
import { Form, Formik } from 'formik';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { MarginContainer } from 'components/layout/Container';
import { Brother, useBrother, useBrotherSemestersList } from 'context/BrotherContext';
import { useList } from 'context/ConfigurationContext';
import InputField from 'components/form/InputField';
import { Chip } from 'primereact/chip';
import ConditionalField from 'components/form/ConditionalField';
import { LocationAutocompleteField } from 'context/LocationContext';
import { useAuth } from 'context/AuthContext';

type UpdateBrotherCallback = (fields: any) => Promise<any>;
interface EditableBrotherCardProps {
    brother: Brother | null,
    updateBrother: UpdateBrotherCallback,
    isAdmin?: boolean,
}

const useEditableBrotherCardController = (updateBrother: UpdateBrotherCallback) => {
    const [loading, setLoading] = useState(false);
    const onSubmit = async (fields: any) => {
        setLoading(true);
        await updateBrother(fields);
        setLoading(false);
    }
    return { loading, onSubmit };
};

const EditableBrotherAdminCard: FC<EditableBrotherCardProps> = ({
    brother, updateBrother, isAdmin = false
}) => {
    const { loading, onSubmit } = useEditableBrotherCardController(updateBrother);
    const initialValues = {
        state: brother?.state ?? '',
    };
    const stateDropdownOptions = [
        { label: 'Public', value: 'public' },
        { label: 'Deleted', value: 'deleted' },
        { label: 'Unknown', value: '' },
    ];
    return (
        <Card title='Admin Panel' className='p-mb-3' style={{ backgroundColor: 'var(--surface-200)' }}>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <Form>
                    <InputField type='dropdown' label='State' options={stateDropdownOptions} name='state' disabled={loading} />
                    <div className='p-d-flex'>
                        <Button label='Update' type='submit' disabled={loading} />
                    </div>
                </Form>
            </Formik>
        </Card>
    )
}

const EditableBrotherBioCard: FC<EditableBrotherCardProps> = ({
    brother, updateBrother
}) => {
    const { loading, onSubmit } = useEditableBrotherCardController(updateBrother);
    const semestersList = useBrotherSemestersList();
    const initialValues = {
        profileURL: brother?.profileURL ?? '',
        realName: brother?.realName ?? '',
        location: brother?.location ?? '',
        school: brother?.school ?? '',
        graduationSemester: brother?.graduationSemester ?? '',
        furtherEducation: brother?.furtherEducation ?? '',
        profession: brother?.profession ?? '',
        aboutMe: brother?.aboutMe ?? '',
        employer: brother?.employer ?? '',
        inUndergrad: brother?.inUndergrad ?? false,
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.realName) {
            errors.realName = 'Required';
        }
        return errors;
    };
    return (
        <Card title='Brother Bio' className='p-mb-3'>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                <Form>
                    <div className='p-fluid p-formgrid p-grid'>
                        <div className='p-ml-2 p-mr-1 p-shadow-2' style={{
                            backgroundImage: `url(${brother?.profileURL})`,
                            backgroundSize: 'cover',
                            backgroundPosition: '50%',
                            width: '4em', height: '4em',
                            borderRadius: '3px',
                        }} />
                        <InputField type='text' label='Profile Picture URL' name='profileURL' disabled={loading} placeholder='No profile image set' col />
                    </div>
                    <InputField type='text' label='Real Name' name='realName' disabled={loading} />
                    <LocationAutocompleteField label='Current Location (City/State)' name='location' disabled={loading} />
                    <InputField type='textarea' label='About Me' name='aboutMe' disabled={loading} />
                    <InputField type='checkbox' label='Are you in Undergrad?' name='inUndergrad' disabled={loading} />
                    <ConditionalField condition={values => values.inUndergrad === true}>
                        <InputField type='dropdown' label='Expected Graduation Semester' name='graduationSemester' disabled={loading}
                            options={semestersList} filter showClear />
                    </ConditionalField>
                    <ConditionalField condition={values => values.inUndergrad === false || values.inUndergrad === undefined || values.inUndergrad === null}>
                        <InputField type='dropdown' label='Graduation Semester' name='graduationSemester' disabled={loading}
                            options={semestersList} filter showClear />
                        <InputField type='textarea' label='Further Education' name='furtherEducation' disabled={loading} />
                        <InputField type='text' label='Current Profession' name='profession' disabled={loading} />
                        <InputField type='text' label='Current Employer' name='employer' disabled={loading} />
                    </ConditionalField>
                    <div className='p-d-flex'>
                        <Button label='Update' type='submit' disabled={loading} />
                    </div>
                </Form>
            </Formik>
        </Card>
    )
}

const EditableBrotherFraternityInfoCard: FC<EditableBrotherCardProps> = ({
    brother, updateBrother,
}) => {
    const { loading, onSubmit } = useEditableBrotherCardController(updateBrother);
    const locationList = useList('locations');
    const semestersList = useBrotherSemestersList();
    const initialValues = {
        brotherName: brother?.brotherName ?? '',
        semesterCrossed: brother?.semesterCrossed ?? '',
        locationCrossed: brother?.locationCrossed ?? '',
        inUndergrad: brother?.inUndergrad ?? false,
        plan: brother?.plan ?? 'free',
    };
    const validate = (values: any) => {
        const errors: any = {};
        if (!values.brotherName) {
            errors.brotherName = 'Required';
        }
        return errors;
    };
    return (
        <Card title='Fraternity Info' className='p-mb-3'>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
                <Form>
                    <InputField type='text' label='Brother Name' name='brotherName' disabled={loading} />
                    <div className='p-fluid p-formgrid p-grid'>
                        <InputField type='dropdown' label='Semester Crossed' name='semesterCrossed'
                            options={semestersList} disabled={loading} filter col />
                        <InputField type='dropdown' label='Location Crossed' name='locationCrossed'
                            options={locationList} disabled={loading} filter col />
                    </div>
                    <ConditionalField condition={values => values.inUndergrad === false}>
                        <InputField type='dropdown' label='Select which plan you want' name='plan'
                            options={[{ label: 'Free', value: 'free' }, { label: 'Paid', value: 'paid' }]} />
                    </ConditionalField>
                    <div className='p-d-flex'>
                        <Button label='Update' type='submit' disabled={loading} />
                    </div>
                </Form>
            </Formik>
        </Card>
    );
}

const EditableSocialLinksCard: FC<EditableBrotherCardProps> = ({
    brother, updateBrother
}) => {
    const { loading, onSubmit } = useEditableBrotherCardController(updateBrother);
    const initialValues = {
        instagram: brother?.instagram ?? '',
        linkedIn: brother?.linkedIn ?? '',
        facebook: brother?.facebook ?? '',
        indeed: brother?.indeed ?? '',
        mattermost: brother?.mattermost ?? '',
        website: brother?.website ?? '',
    };
    return (
        <Card title='Social Links'>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <Form>
                    <InputField type='text' label='Instagram' name='instagram' disabled={loading} placeholder='No Instagram URL set' />
                    <InputField type='text' label='LinkedIn' name='linkedIn' disabled={loading} placeholder='No LinkedIn URL set' />
                    <InputField type='text' label='Mattermost' name='mattermost' disabled={loading} placeholder='No Mattermost URL set' />
                    <InputField type='text' label='Facebook' name='facebook' disabled={loading} placeholder='No Facebook URL set' />
                    <InputField type='text' label='Indeed' name='indeed' disabled={loading} placeholder='No Indeed URL set' />
                    <InputField type='text' label='Website' name='website' disabled={loading} placeholder='No Website URL set' />
                    <div className='p-d-flex'>
                        <Button label='Update' type='submit' disabled={loading} />
                    </div>
                </Form>
            </Formik>
        </Card>
    )
}

const EditableContactInfoCard: FC<EditableBrotherCardProps> = ({
    brother, updateBrother
}) => {
    const { loading, onSubmit } = useEditableBrotherCardController(updateBrother);
    const initialValues = {
        primaryEmail: brother?.primaryEmail ?? '',
        secondaryEmail: brother?.secondaryEmail ?? '',
        cellPhone: brother?.cellPhone ?? '',
        workPhone: brother?.workPhone ?? '',
    };
    return (
        <Card title='Contact Info' subTitle={
            <Chip label='Not Shown in Search' />
        } className='p-mt-3'>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <Form>
                    <InputField type='text' label='Primary Email' name='primaryEmail' disabled={loading} />
                    <InputField type='text' label='Secondary Email' name='secondaryEmail' disabled={loading} />
                    <InputField type='text' label='Cell Phone' name='cellPhone' disabled={loading} />
                    <InputField type='text' label='Work Phone' name='workPhone' disabled={loading} />
                    <div className='p-d-flex'>
                        <Button label='Update' type='submit' disabled={loading} />
                    </div>
                </Form>
            </Formik>
        </Card>
    )
}

interface BrotherEditProps {
    brotherId: string
}
const Page: FC<BrotherEditProps> = ({
    brotherId
}) => {
    const { isAdmin } = useAuth();
    const { brother, updateBrother, loading } = useBrother(brotherId);
    const realName = brother?.realName ?? '';
    // breadcrumb options
    const breadcrumbHome = { icon: 'pi pi-search', url: '/brother/search' };
    const breadcrumbModel = [
        { label: realName, url: `/brother/${brotherId}` },
        { label: 'Edit' }
    ];
    return <>
        <BreadCrumb model={breadcrumbModel} home={breadcrumbHome} />
        <MarginContainer loading={loading}>
            <div className='p-grid'>
                <div className='p-col-12 p-md-6'>
                    {isAdmin && <EditableBrotherAdminCard brother={brother} updateBrother={updateBrother} />}
                    <EditableBrotherBioCard brother={brother} updateBrother={updateBrother} />
                    <EditableBrotherFraternityInfoCard brother={brother} updateBrother={updateBrother} />
                </div>
                <div className='p-col-12 p-md-6'>
                    <EditableSocialLinksCard brother={brother} updateBrother={updateBrother} />
                    <EditableContactInfoCard brother={brother} updateBrother={updateBrother} />
                </div>
            </div>
        </MarginContainer>
    </>;
}

export default Page;