import { FC } from 'react';

import { LoadingSpinner } from 'components/loading/AppLoadingLayer';

interface ContainerProps {
    maxWidth?: string,
    loading?: boolean,
}

export const SingleColumnContainer: FC<ContainerProps> = ({
    maxWidth = '650px',
    loading = false,
    children
}) => {
    return <>
        <div className='p-mt-4 p-mx-3 p-mx-md-auto p-d-flex p-flex-column' style={{ maxWidth }}>
            {loading ? <LoadingSpinner /> : children}
        </div>
    </>;
};

export const MarginContainer: FC<ContainerProps> = ({
    maxWidth = '1200px',
    loading = false,
    children,
}) => {
    return <>
        <div className='p-mx-auto p-px-3 p-my-3' style={{ display: 'flow-root', maxWidth }}>
            {loading ? <LoadingSpinner /> : children}
        </div>
    </>;
}