import { Card } from "primereact/card";
import { Steps } from "primereact/steps";

import Workflow, { SimpleWorkflowState } from "workflows/components/Workflow";
import { ActionButton, ActionFormButton } from "workflows/components/Actions";
import { MarginContainer } from "components/layout/Container";
import { useBrotherCreateController } from "controllers/brother/BrotherCreateController";
import InputField from "components/form/InputField";
import { useBrotherSemestersList } from "context/BrotherContext";
import { useList } from "context/ConfigurationContext";
import ConditionalField from "components/form/ConditionalField";
import { LocationAutocompleteField } from "context/LocationContext";

const brotherCreateWorkflowModel = [
    { label: 'Start' },
    { label: 'School' },
    { label: 'Social' },
    { label: 'Contact' },
];

const validateStartState = (values: any) => {
    const errors: any = {};
    if (!values.realName) {
        errors.realName = 'Required';
    }
    return errors;
}

const validateState1 = (values: any) => {
    const errors: any = {};
    if (!values.brotherName) {
        errors.brotherName = 'Required';
    }
    if (!!values.semesterCrossed !== !!values.locationCrossed) {
        if (!values.semesterCrossed) errors.semesterCrossed = 'Required';
        if (!values.locationCrossed) errors.locationCrossed = 'Required';
    }
    return errors;
}

const BrotherCreate = () => {
    const { brother, updateBrother, loading, BrotherForm } = useBrotherCreateController();
    const locationList = useList('locations');
    const semestersList = useBrotherSemestersList();
    if (brother.state==='public') {
        return (
            <MarginContainer maxWidth='800px'>
                <Card title='Brother Setup'>
                    <div className='p-px-3'>
                        <p>
                            You already have an existing Brother profile. Click below to view it.
                        </p>
                        <div className='p-d-flex p-jc-around'>
                            <ActionButton urlType='path' to={`/brother/${brother?.id}`} label='View Brother' />
                        </div>
                    </div>
                </Card>
            </MarginContainer>
        );
    }
    if (brother.state==='deleted') {
        return (
            <MarginContainer maxWidth='800px'>
                <Card title='Account Deactivated'>
                    <div className='p-px-3'>
                        <p>
                            This account has been deactivated. Please contact an administrator.
                        </p>
                    </div>
                </Card>
            </MarginContainer>
        );
    }
    return (
        <MarginContainer maxWidth='800px'>
            <Workflow initialState='start'>
                <SimpleWorkflowState state='start'>
                    <Card title='Welcome'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={0} />
                        <div className='p-px-3'>
                            <p>
                                This step-by-step guide will help set up your Brother profile.
                                These profiles are publically searchable and enable you to find
                                INΔ brothers with similar interests.
                            </p>
                            <p>
                                Let's start with the basics.
                            </p>
                            <BrotherForm validate={validateStartState}>
                                <InputField type='text' name='realName' label='Your Real Name' disabled={loading} />
                                <div className='p-fluid p-formgrid p-grid'>
                                    <div className='p-ml-2 p-mr-1 p-shadow-2' style={{
                                        backgroundImage: `url(${brother?.profileURL})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: '50%',
                                        width: '4em', height: '4em',
                                        borderRadius: '3px',
                                    }} />
                                    <InputField type='text' label='Profile Picture URL' name='profileURL' disabled={loading} placeholder='No profile image set' col />
                                </div>
                                <LocationAutocompleteField label='City and State' name='location' disabled={loading} />
                                <InputField type='textarea' label='About Me' name='aboutMe' disabled={loading} />
                                <InputField type='checkbox' label='Are you in Undergrad?' name='inUndergrad' disabled={loading} />
                                <div className='p-d-flex p-jc-around'>
                                    <ActionFormButton to='step1' label='Next' />
                                </div>
                            </BrotherForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='step1'>
                    <Card title='Fraternity and School'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={1} />
                        <div className='p-px-3'>
                            <p>
                                Enter your fraternity/school details below.
                            </p>
                            <BrotherForm validate={validateState1}>
                                <InputField type='text' label='Brother Name and Number' name='brotherName' disabled={loading} />
                                <div className='p-fluid p-formgrid p-grid'>
                                    <InputField type='dropdown' label='Semester Crossed' name='semesterCrossed'
                                        options={semestersList} disabled={loading} filter col showClear />
                                    <InputField type='dropdown' label='Location Crossed' name='locationCrossed'
                                        options={locationList} disabled={loading} filter col showClear />
                                </div>
                                <ConditionalField condition={values => values.inUndergrad===true}>
                                    <InputField type='dropdown' label='Expected Graduation Semester' name='graduationSemester' disabled={loading}
                                        options={semestersList} filter showClear/>
                                </ConditionalField>
                                <ConditionalField condition={values => values.inUndergrad===false || values.inUndergrad === undefined || values.inUndergrad === null}>
                                    <InputField type='dropdown' label='Graduation Semester' name='graduationSemester' disabled={loading}
                                        options={semestersList} filter showClear/>
                                    <InputField type='textarea' label='Further Education' name='furtherEducation' disabled={loading} />
                                    <InputField type='text' label='Current Profession' name='profession' disabled={loading} />
                                    <InputField type='text' label='Current Employer' name='employer' disabled={loading} />
                                </ConditionalField>
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionFormButton to='step2' label='Next' />
                                    <ActionButton to='start' label='Go Back' className='p-button-text' />
                                </div>
                            </BrotherForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='step2'>
                    <Card title='Social Profiles'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={2} />
                        <div className='p-px-3'>
                            <p>
                                Enter any social profiles you'd like to link to.
                            </p>
                            <BrotherForm>
                                <InputField type='text' label='Instagram' name='instagram' disabled={loading} placeholder='No Instagram URL set' />
                                <InputField type='text' label='LinkedIn' name='linkedIn' disabled={loading} placeholder='No LinkedIn URL set' />
                                <InputField type='text' label='Mattermost' name='mattermost' disabled={loading} placeholder='No Mattermost URL set' />
                                <InputField type='text' label='Facebook' name='facebook' disabled={loading} placeholder='No Facebook URL set' />
                                <InputField type='text' label='Indeed' name='indeed' disabled={loading} placeholder='No Indeed URL set' />
                                <InputField type='text' label='Website' name='website' disabled={loading} placeholder='No Website URL set' />
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionFormButton to='step3' label='Next' />
                                    <ActionButton to='step1' label='Go Back' className='p-button-text' />
                                </div>
                            </BrotherForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='step3'>
                    <Card title='Contact'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={3} />
                        <div className='p-px-3'>
                            <p>
                                Almost done! Enter your contact information. This is not available through search,
                                but will be used for internal communication purposes.
                            </p>
                            <BrotherForm>
                                <InputField type='text' label='Primary Email' name='primaryEmail' disabled={loading} />
                                <InputField type='text' label='Secondary Email' name='secondaryEmail' disabled={loading} />
                                <InputField type='text' label='Cell Phone' name='cellPhone' disabled={loading} />
                                <InputField type='text' label='Work Phone' name='workPhone' disabled={loading} />
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionFormButton to='confirm' label='Next' />
                                    <ActionButton to='step2' label='Go Back' className='p-button-text' />
                                </div>
                            </BrotherForm>

                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='confirm'>
                    <Card title='Confirmation'>
                        <Steps model={brotherCreateWorkflowModel} activeIndex={10} />
                        <div className='p-px-3'>
                            <p>
                                Congrats {brother?.realName}. You're one click away from finishing your profile.
                                Please take this opportunity to double-check your information before submitting.
                            </p>
                            <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                <ActionButton urlType='path' to={`/brother/${brother?.id}`} label='Complete &amp; View' onClick={async () => {
                                    await updateBrother({ ...brother, state: 'public' });
                                }} />
                                <ActionButton to='step3' label='Go Back' className='p-button-text' />
                            </div>
                        </div>
                    </Card>
                </SimpleWorkflowState>
            </Workflow>
        </MarginContainer>
    )
}

export default BrotherCreate;