import { FC, FormEvent } from 'react';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataView } from 'primereact/dataview';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useSearch } from 'hooks/search/SearchHooks';
import DocumentSearchResult from 'components/dataview/document/DocumentSearchDataView';
import { useAuth } from 'context/AuthContext';
import { useHistory } from 'react-router';
import { SpeedDial } from 'primereact/speeddial';

const sortOptions = [
    { label: 'Name (Asc)', value: 'name' },
    { label: 'Name (Des)', value: '!name' },
    { label: 'Due Date (Asc)', value: 'dueDate' },
    { label: 'Due Date (Des)', value: '!dueDate' },
];

const Page: FC = () => {
    // use search states and hooks
    const {
        searchResultsHeader, itemTemplate,
        queryStates, sortStates,
        results, layout, loading, search,
    } = useSearch('documents', DocumentSearchResult, sortOptions);
    const { isAdmin } = useAuth();
    const history = useHistory();
    const navigateOCL = (path: string) => () => history.push(path);
    // setup search states
    const {
        immediate: [ immediateQuery, setImmediateQuery ],
        applied: [, setAppliedQuery ],
    } = queryStates;
    const {
        field: [ sortField, ],
        order: [ sortOrder, ],
    } = sortStates;
    // form submit listener
    const searchDocumentsOSL = async (e: FormEvent) => {
        e.preventDefault();
        setAppliedQuery(immediateQuery);
        search();
    };
    // speeddial
    const speedDialModel = [];
    if(isAdmin) {
        speedDialModel.push(
            { label: 'Create', icon: 'pi pi-pencil', command: navigateOCL('/document/create') },
        );
    }
    return <>
        <div className='p-mx-auto p-px-3 p-my-3' style={{ display: 'flow-root', maxWidth: '1300px' }}>
            <div className='p-grid'>
                <div className='p-col-12 p-lg-4'>
                    <Card title='Document Search' className='p-mb-2'>
                        <form onSubmit={searchDocumentsOSL}>
                            <div className='p-fluid'>
                                <div className='p-field p-d-flex'>
                                    <InputText value={immediateQuery} onChange={e => setImmediateQuery(e.target.value)}
                                        id='search_input' className='p-inputtext-lg p-d-block' placeholder='Search' />
                                    <Button icon='pi pi-search' className='p-ml-1' style={{ width: '6ch' }} />
                                </div>
                            </div>
                        </form>
                    </Card>
                </div>
                <div className='p-col-12 p-lg-8'>
                    <div className='p-shadow-2' style={{ backgroundColor: 'var(--surface-100)', borderRadius: '3px', overflow: 'hidden' }}>
                        {loading ? (
                            <div className='p-d-flex p-jc-center p-ai-center' style={{ height: '100%' }}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataView value={results} layout={layout}
                                header={searchResultsHeader}
                                itemTemplate={itemTemplate}
                                sortField={sortField} sortOrder={sortOrder}
                                paginator rows={6} />
                        )}
                    </div>
                </div>
            </div>
        </div>
        {isAdmin && <>
            <SpeedDial direction='left' model={speedDialModel}
                style={{ position: 'fixed', bottom: '2rem', right: '2rem' }} className='speeddial-left' />
        </>}
    </>;
}

export default Page;