import { FC } from "react";
import { useHistory } from "react-router";

import { Button } from "primereact/button";

import { useWorkflowContext } from "./Workflow";
import { useFormikContext } from "formik";

interface ActionButtonProps {
    to: string,
    label: string,
    urlType?: 'state' | 'path',
    className?: string,
    onClick?: Function,
    type?: any,
}
export const ActionButton: FC<ActionButtonProps> = ({
    to, label, type = 'button', onClick,
    urlType = 'state', className,
}) => {
    const workflow = useWorkflowContext();
    const history = useHistory();
    if (urlType === 'path') {
        const navigateOCL = async () => {
            if (onClick) await onClick();
            history.push(to);
        };
        return (
            <Button onClick={navigateOCL} label={label} className={className} type={type} />
        );
    }
    const changeStateOCL = async () => {
        if (onClick) await onClick();
        workflow.changeState(to);
    };
    return (
        <Button onClick={changeStateOCL} label={label} className={className} type={type} />
    );
}

type ActionFormButtonProps = ActionButtonProps;
export const ActionFormButton: FC<ActionFormButtonProps> = (props) => {
    const formik = useFormikContext<any>();
    const submitFormikForm = async () => {
        if (props.onClick) await props.onClick();
        const formErrors = await formik.validateForm();
        if (Object.keys(formErrors).length === 0) {
            await formik.submitForm();
        } else {
            throw formErrors;
        }
    }
    return <ActionButton type='submit' onClick={submitFormikForm} {...props} />
}