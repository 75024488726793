import { action, makeObservable, observable } from 'mobx';

import { firebaseApp } from 'config/firebase';
import { breezeConfig } from 'config/breeze';
import FirestoreCollectionService from 'services/FirestoreCollectionService';
import { toMap } from 'util/functional/ListUtil';

const serviceConfig = {
    db: firebaseApp.firestore(),
    collectionName: 'config',
    objectSortColumn: 'id',
    objectSortDirection: 'desc',
};
const storeConfig = {
    store: false,
    objectCollection: false,
    lists: observable,
    listMaps: observable,
    mattermostUrl: observable,
    setLists: action,
    setListMaps: action,
    setMattermostURL: action,
};

class ConfigurationStore extends FirestoreCollectionService {
    store = null;
    activeId = null;
    lists = null;
    listMaps = null;
    mattermostUrl = null;
    roles = null;

    constructor(store) {
        super(serviceConfig);
        makeObservable(this, storeConfig);
        this.store = store;
    }

    initialize = async () => {
        const { activeConfig } = breezeConfig;
        const config = await this.getObject(activeConfig, false);
        this.activeId = activeConfig;
        if (config.lists) {
            this.setLists(config.lists);
            this.setListMaps(config.lists);
        }
        if (config.mattermost?.url) {
            this.setMattermostURL(config.mattermost.url);
        }
    }

    setLists = (lists) => {
        this.lists = lists;
    }

    setListMaps = (lists) => {
        const tempListMap = {};
        for (const listKey in lists) {
            const list = lists[listKey];
            tempListMap[listKey] = toMap(list, i => i.key);
        }
        this.listMaps = tempListMap;
    }

    setMattermostURL = (mattermostUrl) => {
        this.mattermostUrl = mattermostUrl;
    }

    objectCollection = (view = 'default') => {
        let collectionRef = this.db.collection(this.collectionName);
        return collectionRef;
    };
}

export default ConfigurationStore;