import { FC } from 'react';

import { AppContext, withSecureContext } from 'context/AppContext';
import { History, Location } from 'history';
import { Route, Switch } from 'react-router';

import AdminDashboard from './AdminDashboard';
import AdminGroups from './AdminGroups';
import AdminUserManagement from './AdminUserManagement';
import AdminSystem from './AdminSystem';
import AdminChangeRole from './AdminChangeRole';

interface SwitchProps {
    context: AppContext,
    location: Location,
    history: History
}
const PageSwitch: FC<SwitchProps> = ({
    location,
}) => {
    return (
        <Switch location={location}>
            <Route exact path='/admin' component={AdminDashboard} />
            <Route exact path='/admin/users' component={AdminUserManagement} />
            <Route exact path='/admin/groups' component={AdminGroups} />
            <Route exact path='/admin/system' component={AdminSystem} />
            <Route exact path='/admin/changeRole' component={AdminChangeRole} />
        </Switch>
    );
}

export default withSecureContext(PageSwitch);
