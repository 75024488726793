import { FC } from 'react';

import { Avatar } from 'primereact/avatar';

import { useListLabels } from 'context/ConfigurationContext';
import SearchResult from 'components/dataview/SearchDataView';

export type BrotherSearchResultLayout = 'list' | 'grid';

export interface BrotherSearchResultProps {
    brotherName: string,
    realName: string,
    location: string,
    locationCrossed: string,
    semesterCrossed: string,
    profileURL: string,
    objectID: string,
    layout: BrotherSearchResultLayout,
}
const BrotherSearchResult: FC<BrotherSearchResultProps> = ({
    objectID, profileURL,
    semesterCrossed, locationCrossed,
    realName, brotherName,
    layout = 'list'
}) => {
    const locations = useListLabels('locations');
    const locationString = locations ? `${semesterCrossed}, ${locations[locationCrossed]}` : '';
    const avatar = profileURL ? (
        <Avatar image={profileURL} imageAlt={brotherName}
            className='p-ml-3 p-shadow-1' shape='square'
            size='large' style={{ overflow: 'hidden' }} />
    ) : (
        <Avatar icon='pi pi-user' imageAlt={brotherName}
            className='p-ml-3 p-shadow-1' shape='square'
            size='large' style={{ overflow: 'hidden' }} />
    );
    const header = profileURL ? (
        <img src={profileURL} alt={brotherName}
            style={{ height: '12em', objectFit: 'cover', objectPosition: '50% 40%' }} />
    ) : (
        <div style={{ height: '12em', width: '100%', backgroundColor: 'var(--surface-400)' }} />
    );
    return (
        <SearchResult
            title={realName} subTitle={brotherName} description={locationString}
            linkUrl={`/brother/${objectID}`} icon={avatar} layout={layout}
            header={header} />
    );
}

export default BrotherSearchResult;