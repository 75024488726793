import { FC, FormEvent, useMemo, useState } from 'react';

import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import { DataTable } from 'primereact/datatable';
import { Divider } from 'primereact/divider';
import { SpeedDial } from 'primereact/speeddial';
import { Chip } from 'primereact/chip';
import { ProgressSpinner } from 'primereact/progressspinner';

import { useList, useListLabels } from 'context/ConfigurationContext';
import { useSearch, useSearchParams } from 'hooks/search/SearchHooks';
import BrotherSearchResult, { BrotherSearchResultProps } from 'components/dataview/brother/BrotherSearchDataView';
import { MarginContainer } from 'components/layout/Container';
import { Column } from 'primereact/column';
import { useHistory } from 'react-router';

function getOrDefault(params: URLSearchParams, key: string, defaultValue: string = ''): string {
    if (params.has(key)) {
        const value = params.get(key);
        if (value !== null) {
            return value;
        }
    }
    return defaultValue;
}

const sortOptions = [
    { label: 'Name (Asc)', value: 'realName' },
    { label: 'Name (Des)', value: '!realName' },
    { label: 'Brother Name (Asc)', value: 'brotherName' },
    { label: 'Brother Name (Des)', value: '!brotherName' },
];

const Page: FC = () => {
    const locationsList = useList('locations');
    const locationsMap = useListLabels('locations');
    // get url search params
    const { searchParams } = useSearchParams();
    // location filter
    const defaultLocationFilter = getOrDefault(searchParams, 'locationCrossed', '');

    const [selected, setSelected] = useState<any>();
    const [appliedLocationFilter, setAppliedLocationFilter] = useState<string>(defaultLocationFilter);
    const [immediateLocationFilter, setImmediateLocationFilter] = useState<string>(defaultLocationFilter);
    // package search filters
    const filters = useMemo(() => [
        {
            label: 'Location', key: 'locationCrossed',
            value: appliedLocationFilter,
            displayValue: locationsMap ? locationsMap[appliedLocationFilter] : '',
            reset: () => {
                setAppliedLocationFilter('');
                setImmediateLocationFilter('');
            }
        },
    ], [locationsMap, appliedLocationFilter]);
    // use search states and hooks
    const {
        queryStates, appliedFilters,
        results, loading, search,
    } = useSearch('brothers', BrotherSearchResult, sortOptions, filters);
    // setup search states
    const {
        immediate: [immediateQuery, setImmediateQuery],
        applied: [, setAppliedQuery],
    } = queryStates;
    const searchBrothersOSL = async (e: FormEvent) => {
        e.preventDefault();
        setAppliedQuery(immediateQuery);
        setAppliedLocationFilter(immediateLocationFilter);
        search();
    };
    const profileImageTemplate: (rowData: BrotherSearchResultProps) => any = (rowData) => {
        if (rowData.profileURL) {
            return <Avatar image={rowData.profileURL} size='normal' shape='circle' />;
        } else {
            return <Avatar icon='pi pi-user' size='normal' shape='circle' />;
        }
    };
    const history = useHistory();
    const numSelected = (selected || []).length;
    const speedDialModelMulti = [
        { label: 'Add to Group', icon: 'pi pi-users', command: () => { } },
    ];
    const speedDialModelSingle = [
        { label: 'Add to Group', icon: 'pi pi-users', command: () => { } },
        { label: 'Update', icon: 'pi pi-pencil', command: () => { history.push(`/brother/${selected[0].objectID}/edit`); } },
        { label: 'View', icon: 'pi pi-eye', command: () => { history.push(`/brother/${selected[0].objectID}`); } },
        { label: 'Delete', icon: 'pi pi-trash', command: () => { } },
    ];
    const speedDialModelNoneSelected = [
        { label: 'Create', icon: 'pi pi-users', command: () => { history.push(`/brother/create`) }},
    ];
    const breadcrumbHome = { icon: 'pi pi-cog', url: '/admin' };
    const breadcrumbModel = [{ label: 'User Management' }];
    return <>
        <BreadCrumb model={breadcrumbModel} home={breadcrumbHome} />
        <MarginContainer maxWidth='1300px'>
            <div className='p-grid'>
                <div className='p-col-12 p-lg-4'>
                    <form onSubmit={searchBrothersOSL}>
                        <Card title='User Management' className='p-mb-2'>
                            <div className='p-fluid'>
                                <div className='p-field p-d-flex'>
                                    <InputText value={immediateQuery} onChange={e => setImmediateQuery(e.target.value)}
                                        id='search_input' className='p-inputtext-lg p-d-block' placeholder='Search' />
                                    <Button icon='pi pi-search' className='p-ml-1' style={{ width: '6ch' }} disabled={loading} />
                                </div>
                            </div>
                            <div className='p-d-flex p-flex-wrap p-my-3'>
                                {appliedFilters.map(({ key, displayValue, reset }) => (
                                    <Chip key={key} label={displayValue}
                                        removable onRemove={reset} className='p-mr-2 p-mb-2 primary-bg' />
                                ))}
                            </div>
                            <Divider>
                                <div className="p-d-inline-flex p-ai-center">
                                    <i className="pi pi-filter p-mr-2"></i>
                                    <b>Filters</b>
                                </div>
                            </Divider>
                            <div className='p-fluid'>
                                <div className='p-field'>
                                    <Dropdown options={locationsList} optionLabel='label' optionValue='value' placeholder='Filter by Location'
                                        value={immediateLocationFilter} onChange={e => setImmediateLocationFilter(e.value)} />
                                </div>
                            </div>
                        </Card>
                    </form>
                </div>
                <div className='p-col-12 p-lg-8'>
                    <div className='p-shadow-2' style={{ backgroundColor: 'var(--surface-100)', borderRadius: '4px', overflow: 'hidden' }}>
                        {loading ? (
                            <div className='p-d-flex p-jc-center p-ai-center' style={{ height: '100%' }}>
                                <ProgressSpinner />
                            </div>
                        ) : (
                            <DataTable value={results} dataKey='objectID' paginator rows={10}
                                selection={selected} onSelectionChange={e => setSelected(e.value)}>
                                <Column selectionMode='multiple' headerStyle={{ width: '3em' }}></Column>
                                <Column field='profileURL' header='Profile' headerStyle={{ width: '5em' }} body={profileImageTemplate} />
                                <Column field='brotherName' header='Brother Name' sortable />
                                <Column field='realName' header='Name' sortable />
                                <Column field='state' header='State' sortable />
                                {/* <Column field='locationCrossed' header='Location' body={locationTemplate} sortable /> */}
                            </DataTable>
                        )}
                    </div>
                </div>
            </div>
        </MarginContainer>
        {/*Speed Dial*/}
        {numSelected === 0 ? (
            <SpeedDial direction='left' model={speedDialModelNoneSelected} radius={100}
            style={{ position: 'fixed', bottom: '2rem', right: '2rem' }} className='speeddial-bottom-left' />
        ) : numSelected === 1 ? <>
            {/* <Tooltip target=".speeddial-single-bottom-left .p-speeddial-action" /> */}
            <SpeedDial direction='up-left' model={speedDialModelSingle} radius={100} type='quarter-circle'
                style={{ position: 'fixed', bottom: '2rem', right: '2rem' }} className='speeddial-single-bottom-left' />
        </> : <>
            {/* <Tooltip target=".speeddial-bottom-left .p-speeddial-action" /> */}
            <SpeedDial direction='left' model={speedDialModelMulti} radius={100}
                style={{ position: 'fixed', bottom: '2rem', right: '2rem' }} className='speeddial-bottom-left' />
        </>}

    </>;
}

export default Page;