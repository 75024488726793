import { makeAutoObservable } from 'mobx';

import { firebaseApp } from 'config/firebase';

const serviceConfig = {
    storage: firebaseApp.storage()
};
const storeConfig = {
    store: false,
    storage: false,
};

class FileStorageStore {
    store = null;
    storage = serviceConfig.storage;

    constructor(store) {
        makeAutoObservable(this, storeConfig);
        this.store = store;
    }

    file = (path) => {
        return this.storage.ref().child(path);
    }

    uploadFile = (path, contents) => {
        return this.file(path).put(contents);
    };

    deleteFile = (path) => {
        return this.file(path).delete();
    }

    getDownloadURL = (path) => {
        return this.file(path).getDownloadURL();
    };

    getFileInformation = (path) => {
        return this.file(path).getMetadata();
    }
}

export default FileStorageStore;