import { FC, useState } from "react";
import { Form, Formik } from "formik";

import { Document, useDocument } from "context/DocumentContext";
import { useAppContext } from "context/AppContext";

export type DocumentDeleteFormValidation = (values: Document) => object;
interface DocumentFormProps {
    validate?: DocumentDeleteFormValidation
}

export const useDocumentDeleteController = (documentId: string) => {
    const { documents } = useAppContext();

    const [loading, setLoading] = useState(false);
    const doc = useDocument(documentId);
    const [deleted, setDeleted] = useState(false);

    const onSubmit = async () => {
        setLoading(true);
        await documents.deleteObject(documentId);
        setDeleted(true);
        setLoading(false);
    };
    
    const DeleteDocumentForm: FC<DocumentFormProps> = ({ children, validate }) => {
        return (
            <Formik onSubmit={onSubmit} initialValues={doc!} validate={validate}>
                <Form>
                    {children}
                </Form>
            </Formik>
        );
    };
    return {
        doc, deleted, loading, DeleteDocumentForm,
    };
}