import { MarginContainer } from "components/layout/Container";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { FC } from "react";

const Page: FC = () => {
    const breadcrumbHome = { icon: 'pi pi-cog', url: '/admin' };
    const breadcrumbModel = [{ label: 'Groups' }];
    return <>
        <BreadCrumb model={breadcrumbModel} home={breadcrumbHome} />
        <MarginContainer maxWidth='800px' >
            <Card title='Group Management'>
                <p>This page is under construction</p>
            </Card>
        </MarginContainer>
    </>;
}

export default Page;