import { useState } from 'react';

export interface WorkflowProps {
    validateTransaction?: (oldState: string, newState: string) => boolean,
    initialState?: string,
}

export interface WorkflowContextProps {
    state: string,
    error?: string,
    changeState: (newState: string) => void,
    clearError: () => void,
}

export const defaultWorkflowContext: WorkflowContextProps = {
    state: 'initial',
    error: undefined,
    changeState: () => { },
    clearError: () => { },
}

export const useWorkflowController: (props: WorkflowProps) => WorkflowContextProps = ({
    validateTransaction = () => true,
    initialState = defaultWorkflowContext.state,
}) => {
    const [state, _setState] = useState(initialState);
    const [error, _setError] = useState<string>();
    const changeState = (newState: string) => {
        if (validateTransaction(state, newState)) {
            _setState(newState);
        } else {
            _setError(`Disallowed Transition: ${state} => ${newState}`);
        }
    };
    const clearError = () => { _setError(undefined); }
    return {
        state, changeState,
        error, clearError,
    };
}