import { FC } from 'react';

import { AppContext, withSecureContext } from 'context/AppContext';
import { History, Location } from 'history';
import { Route, Switch } from 'react-router';

import BrotherSearch from './BrotherSearch';
import BrotherView from './BrotherView';
import BrotherEdit from './BrotherEdit';
import BrotherCreate from './BrotherCreate';

interface SwitchProps {
    context: AppContext,
    location: Location,
    history: History
}
const PageSwitch: FC<SwitchProps> = ({
    location,
}) => {
    return (
        <Switch location={location}>
            <Route exact path='/brother/create' component={BrotherCreate} />
            <Route exact path='/brother/search' component={BrotherSearch} />
            <Route exact path='/brother/:brotherId' >
                {({ match }) => {
                    const brotherId = match!.params.brotherId;
                    return <BrotherView brotherId={brotherId} />
                }}
            </Route>
            <Route exact path='/brother/:brotherId/edit' >
                {({ match }) => {
                    const brotherId = match!.params.brotherId;
                    return <BrotherEdit brotherId={brotherId} />
                }}
            </Route>
        </Switch>
    );
}

export default withSecureContext(PageSwitch);