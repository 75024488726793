import { FC } from 'react';
import { Link } from 'react-router-dom';

import { MarginContainer } from 'components/layout/Container';

import './AdminDashboard.scss';

const Dashboard: FC = () => (
    <MarginContainer maxWidth='800px'>
        <div className='p-d-flex p-flex-column'>
            <nav className='admin-menu'>
                <ul>
                    <li><Link to='/admin/changeRole'>Change Role</Link></li>
                    <li><Link to='/admin/users'>Manage Users</Link></li>
                    <li><Link to='/admin/groups'>Manage Groups</Link></li>
                    <li><Link to='/admin/system'>Manage System</Link></li>
                </ul>
            </nav>
        </div>
    </MarginContainer>
);

export default Dashboard;