import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Chip } from 'primereact/chip';
import { DataViewLayoutType } from 'primereact/dataview';
import { Card } from 'primereact/card';

export interface SearchResultProps {
    title: string,
    subTitle: string,
    description: string,
    alert?: string,
    header?: any,
    footer?: any,
    icon?: any,
    linkUrl: any,
    layout: DataViewLayoutType
}
const SearchResult: FC<SearchResultProps> = ({
    title, subTitle, description,
    header = undefined, footer = undefined,
    icon,
    linkUrl,
    alert,
    layout = 'list'
}) => {
    if (layout === 'list') {
        const documentInformation = (
            <Link to={linkUrl} style={{ textDecoration: 'none', color: 'unset', }}>
                <div className='p-grid p-justify-start p-align-center' style={{ height: '100%' }}>
                    <div className='p-col-12 p-md-6 p-grid p-justify-center p-align-center'>
                        <div className='p-col-fixed p-d-flex p-flex-column p-ai-center' style={{ width: '5em' }}>
                            {icon}
                        </div>
                        <div className='p-col'>
                            <p style={{ fontSize: '1.1rem', fontWeight: 'bold' }} className='p-mt-1 p-mb-0'>
                                {title}
                            </p>
                            <p style={{ fontSize: '1.0rem' }} className='p-mt-1 p-mb-1'>
                                {subTitle}
                            </p>
                        </div>
                    </div>
                    <div className='p-col-12 p-md-6 p-px-4'>
                        <p style={{ fontSize: '1.0rem' }} className='p-mt-1 p-mb-1'>
                            {description}
                        </p>
                        {!!alert && <Chip label={alert} />}
                    </div>
                </div>
            </Link>
        );
        return <>
            <div key={linkUrl} className='p-col-12 dataview-list-item'>
                {documentInformation}
            </div>
        </>;
    }
    return <>
        <div key={linkUrl} className='p-col-12 p-sm-6 p-md-4 dataview-grid-card-container'>
            <Link to={linkUrl} style={{ textDecoration: 'none', color: 'unset', }}>
                <Card title={title} subTitle={subTitle}
                    header={header} footer={footer}
                    className='p-shadow-2 dataview-grid-card'>
                    {description}
                    <div className='p-mt-2'>
                        {!!alert && <Chip label={alert} />}
                    </div>
                </Card>
            </Link>
        </div>
    </>;
}

export default SearchResult;