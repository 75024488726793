
const year = (new Date()).getFullYear();

function AppFooter() {
    return <>
        <div className='p-mx-auto p-my-5 p-d-flex p-jc-center' style={{ color: 'var(--text-color)' }}>
            &copy; {year} Iota Nu Delta
        </div>
    </>;
}

export default AppFooter;