import { FC, createContext, lazy, useContext } from 'react';
import { observer } from 'mobx-react-lite';

import RootStore from './stores/RootStore';
import AuthenticationStore from './stores/AuthenticationStore';

const rootStore = new RootStore();
export type AppContext = RootStore;

const InternalAppContext = createContext<AppContext>(rootStore);
export const AppContextProvider: FC = ({ children }) => (
    <InternalAppContext.Provider value={rootStore}>
        {children}
    </InternalAppContext.Provider>
);
export const withContext = (Component: any) => (props: any) => (
    <InternalAppContext.Consumer>
        {context => <Component context={context} {...props} />}
    </InternalAppContext.Consumer>
);
export const useAppContext = () => useContext<AppContext>(InternalAppContext);

interface AuthBoundaryProps {
    auth: AuthenticationStore,
    children: any,
    fallback: any
}
const AuthSignInBoundary: FC<AuthBoundaryProps> = observer(({
    auth, fallback, children
}) => {
    if (auth.isLoggedIn) {
        return children;
    }
    return fallback;
});
const DefaultLoginPage = lazy(() => import('pages/Login'));
export const withSecureContext = (Component: any, Fallback = DefaultLoginPage) => (props: any) => (
    <InternalAppContext.Consumer>
        {context => (
            <AuthSignInBoundary auth={context.auth}
                fallback={<Fallback context={context} {...props} />}>
                <Component context={context} {...props} />
            </AuthSignInBoundary>
        )}
    </InternalAppContext.Consumer>
);