import { useEffect, useMemo, useState } from 'react';

import { useAppContext } from './AppContext';

export interface Brother {
    id: string,
    locationCrossed?: string, semesterCrossed?: string, graduationSemester?: string,
    undergradMajor?: string, inUndergrad?: boolean, furtherEducation?: string,
    profession?: string, employer?: string, location?: { description: string }, school?: string,
    brotherName?: string, realName: string,
    profileURL?: string, aboutMe?: string,
    instagram?: string, mattermost?: string, linkedIn?: string,
    facebook?: string, indeed?: string, website?: string,
    primaryEmail?: string, secondaryEmail?: string, cellPhone?: string, workPhone?: string,
    plan?: 'paid' | 'free'
    state?: 'public' | 'deleted' | ''
}

export const useBrother = (brotherId: string) => {
    const { brothers } = useAppContext();
    const [brother, setBrother] = useState<Brother>({} as Brother);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    // fetch latest brother details
    useEffect(() => {
        (async () => {
            if (brotherId) {
                setLoading(true);
                const details = await brothers.getBrotherDetails(brotherId) as Brother;
                setBrother(details);
                setLoading(false);
                setLoaded(true);
            }
        })();
    }, [brotherId, setBrother, setLoading, brothers]);
    const updateBrother = async (newFields: any) => {
        await brothers.updateObject(brotherId, newFields);
        setBrother(await brothers.getObject(brotherId));
    };
    return { brother, updateBrother, loading, loaded };
}

const currentYear = (new Date()).getFullYear();
const foundingYear = 1994;
const yearsSinceBeginning = currentYear - foundingYear + 1;
export const yearsList = Array.from({ length: yearsSinceBeginning }, (_, i) => foundingYear + i);
export const semestersList = ['Spring', 'Summer', 'Fall'];

export const useBrotherSemestersList = () => {
    return useMemo(() => {
        return yearsList
            .flatMap(y => [...semestersList.map(s => `${s} ${y}`)])
            .map(s => ({ value: s, label: s }));
    }, []);
}

export const useBrotherGroupedSemestersList = () => {
    return useMemo(() => {
        return yearsList.map(y => ({
            label: `${y}`,
            code: `${y}`,
            items: semestersList.map(s => ({ label: `${s} ${y}`, value: `${s} ${y}` }))
        }));
    }, []);
}