import { FC, MouseEvent, MouseEventHandler, useState } from 'react';

import { Button } from 'primereact/button';

import { AppContext, withContext } from 'context/AppContext';

export function getFileExtension(file: string) {
    if(file) return file.substring(file.lastIndexOf('.')).toLowerCase();
}

export function calculateFileIcon(file: string) {
    const fileExt = getFileExtension(file);
    switch (fileExt) {
        case '.pdf':
            return 'pi pi-file-pdf';
        case '.xls':
        case '.xlsx':
            return 'pi pi-file-excel';
        case '.jpg':
        case '.jpeg':
        case '.webp':
        case '.svg':
        case '.png':
            return 'pi pi-image';
        case '.doc':
        case '.docx':
            return 'pi pi-copy';
        default:
            return 'pi pi-file';
    }
}

interface DocumentDownloadButtonProps {
    context: AppContext,
    className: string,
    filePath: string,
}
const FileDownloadButton: FC<DocumentDownloadButtonProps> = ({
    context,
    className, filePath,
}) => {
    const [downloadURL, setDownloadURL] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const downloadReady = downloadURL !== undefined;
    const prepareDownloadOCL: MouseEventHandler = async (e: MouseEvent) => {
        if (!downloadReady) {
            setLoading(true);
            const url = await context.storage.getDownloadURL(filePath);
            setDownloadURL(url);
            setLoading(false);
        }
        e.stopPropagation();
    }
    const downloadOCL: MouseEventHandler = async (e: MouseEvent) => {
        if (downloadReady) {
            window.open(downloadURL, '_blank');
        }
        e.stopPropagation();
    }
    const fileIcon = calculateFileIcon(filePath);
    if (downloadReady) {
        return (
            <Button icon='pi pi-download' iconPos='right'
                tooltip='Download File' tooltipOptions={{ position: 'bottom' }}
                className={`p-button-rounded ${className}`}
                onClick={downloadOCL} />
        );
    }
    return (
        <Button icon={fileIcon} iconPos='right' loading={loading}
            tooltip='Get File Info' tooltipOptions={{ position: 'bottom' }}
            className={`p-button-rounded p-button-outlined ${className}`}
            onClick={prepareDownloadOCL} />
    );
}

export default withContext(FileDownloadButton);