import { makeAutoObservable } from 'mobx';

const storeConfig = {
    store: false,
};

class PreferenceStore {
    darkTheme = false;
    store = null;

    constructor(store) {
        makeAutoObservable(this, storeConfig);
        this.store = store;
    }

    toggleDarkTheme = () => {
        this.darkTheme = !this.darkTheme;
    };
}

export default PreferenceStore;