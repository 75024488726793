import { makeObservable } from 'mobx';

import { firebaseApp } from 'config/firebase';
import FirestoreCollectionService from 'services/FirestoreCollectionService';

const serviceConfig = {
  db: firebaseApp.firestore(),
  collectionName: 'notifications',
  objectSortColumn: 'createdOn',
  objectSortDirection: 'desc',
};
const storeConfig = {
  store: false,
  objectCollection: false,
};

class BrotherNotificationStore extends FirestoreCollectionService {
  store = null;

  constructor(store) {
    super(serviceConfig);
    makeObservable(this, storeConfig);
    this.store = store;
  }

  objectCollection = (view) => {
    const userId = this.store.auth.user.uid;
    let collectionRef = this.db.collection(`${this.collectionName}/${userId}/${view}`);
    return collectionRef;
  };

  getRecentObjects = async (numObjects = 8, view = 'system') => {
    if (view !== this.viewType) {
      this.updateViewType(view);
    }
    //get all recent Objects
    const snapshot = await this.objectCollection(view)
      .orderBy(this.objectSortColumn, this.objectSortDirection)
      .limit(numObjects)
      .get();
    const unwrappedData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //refresh caches
    for (let obj of unwrappedData) {
      obj = await this.processAndCacheObject(obj);
    }
    this.updateRecentObjects(unwrappedData);
    return unwrappedData;
  };

  getUserNotifications = async () => {
    return this.getRecentObjects(10, 'user');
  }

  getSystemNotifications = async () => {
    return this.getRecentObjects(10, 'system');
  }

  updateRecentObjects = (newRecentList) => {
    this.recentObjects = newRecentList;
  }
}

export default BrotherNotificationStore;