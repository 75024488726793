/**
 * Customizes a Breeze-based application
 */

 export const appDrawerElements = [
    { label: 'Home', type: 'link', to: '/' },
    { label: 'Brother Search', type: 'link', to: '/brothers'},
    { label: 'Community', type: 'link', to: '/community'},
    { label: 'Documents', type: 'link', to: '/documents'},
];

export const breezeConfig = {
    activeConfig: 'initial',
    navigation: {
        appDrawer: {
            elements: appDrawerElements
        }
    }
};

export default breezeConfig;