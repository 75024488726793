import { FC } from 'react';

import { AppContext, withSecureContext } from 'context/AppContext';
import { History, Location } from 'history';
import { Route, Switch } from 'react-router';

import DocumentSearch from './DocumentSearch';
import DocumentView from './DocumentView';
import DocumentCreate from './DocumentCreate';
import DocumentDelete from './DocumentDelete';

interface SwitchProps {
    context: AppContext,
    location: Location,
    history: History
}
const PageSwitch: FC<SwitchProps> = ({
    location,
}) => {
    return (
        <Switch location={location}>
            <Route exact path='/document/create' component={DocumentCreate} />
            <Route exact path='/document/search' component={DocumentSearch} />
            <Route exact path='/document/:documentId' >
                {({ match }) => {
                    const brotherId = match!.params.documentId;
                    return <DocumentView documentId={brotherId} />
                }}
            </Route>
            <Route exact path='/document/:documentId/delete' >
                {({ match }) => {
                    const brotherId = match!.params.documentId;
                    return <DocumentDelete documentId={brotherId} />
                }}
            </Route>
        </Switch>
    );
}

export default withSecureContext(PageSwitch);