import { useMemo } from 'react';
import { useAppContext } from './AppContext';

export const useListLabels = (listName: string) => {
    const { config } = useAppContext();
    // make local copy of list map so we can manipulate it
    return useMemo(() => {
        const listMap = { ...config.listMaps[listName] };
        for(const k in listMap) {
            listMap[k] = listMap[k].value;
        }
        return listMap;
    }, [config.listMaps, listName]);
}

export const useList = (listName: string) => {
    const { config } = useAppContext();
    return useMemo(() => config.lists[listName].map((obj: any) => ({ label: obj.value, value: obj.key })), [config.lists, listName]);
}