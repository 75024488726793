import { FC, useState } from "react";

import { useAppContext } from "context/AppContext";
import { Brother, useBrother } from "context/BrotherContext";
import { Form, Formik, FormikProps } from "formik";

export type BrotherCreateFormValidation = (values: Brother) => object;
interface BrotherFormProps {
    validate?: BrotherCreateFormValidation
}

const emptyBrother = {} as Brother;
export const useBrotherCreateController = () => {
    const { auth } = useAppContext();
    const { brother, updateBrother, loading } = useBrother(auth.user.uid);
    const onSubmit = async (fields: any) => {
        await updateBrother(fields);
    }
    const BrotherForm: FC<BrotherFormProps> = ({ children, validate }) => {
        return (
            <Formik onSubmit={onSubmit} initialValues={brother} validate={validate}>
                <Form>
                    {children}
                </Form>
            </Formik>
        );
    };
    return {
        brother, updateBrother,
        loading, BrotherForm,
    };
}