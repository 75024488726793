import { FC } from 'react';

import { DataViewLayoutType } from 'primereact/dataview';

import { dateInPast, pastDateToRTF } from 'util/date/DateUtil';
import { calculateFileIcon, getFileExtension } from 'components/buttons/download/FileDownloadButton';
import SearchResult from 'components/dataview/SearchDataView';
import { FileInformation } from 'context/DocumentContext';

export interface DocumentSearchResultProps {
    name: string,
    type: string,
    description: string,
    file?: string,
    files?: FileInformation[],
    objectID: string,
    dueDate: Date,
    createdOn: Date,
    updatedOn: Date,
    layout: DataViewLayoutType,
}
const DocumentSearchResult: FC<DocumentSearchResultProps> = ({
    objectID, name, type, description, file, files = [], dueDate,
    layout = 'list'
}) => {
    const displayFile = (file || files[0].name) ?? '';
    const fileIcon = <>
        <i className={calculateFileIcon(displayFile)} style={{ fontSize: '1.5rem' }} />
        <span style={{ fontSize: '0.8rem' }}>{getFileExtension(displayFile)}</span>
    </>;
    return (
        <SearchResult
            title={name} subTitle={type} description={description}
            alert={dateInPast(dueDate) ? `Due ${pastDateToRTF(dueDate)}` : undefined}
            linkUrl={`/document/${objectID}`} icon={fileIcon} layout={layout}
            footer={
                <div className='p-d-flex p-flex-column p-ai-center' style={{ width: 'fit-content' }}>
                    {fileIcon}
                </div>
            } />
    );
}

export default DocumentSearchResult;