import { useEffect, useState } from 'react';

import { useAppContext } from './AppContext';

import { dateFromTimestamp } from 'util/date/DateUtil';
import { useAuth } from './AuthContext';
import { timestampFromDate, useTimestamp } from 'config/firebase';

export interface Document {
    id: string,
    name: string,
    type: string,
    description: string,
    directions?: string,
    file?: string,
    files?: FileInformation[],
    owner: string,
    groupOwner?: string,
    acceptUserSubmissions: boolean,
    access?: string,
    accessType?: 'location' | 'role' | 'group' | 'any',
    category?: 'key' | 'shared' | 'private',
    dueDate?: Date,
    createdOn: Date,
    updatedOn: Date,
    state?: 'public' | 'deleted',
}

export interface FileInformation {
    path: string,
    name: string,
    size: number,
    type: string,
}

export const useDocuments = () => {
    const { documents } = useAppContext();
    const [ docs, setDocs ] = useState<Document[]>([]);
    useEffect(() => {(async () => {
        const recentDocs = await documents.getRecentDocuments() as Document[];
        const parsedData = recentDocs.map((d: any) => ({
            ...d,
            dueDate: d.dueDate ? dateFromTimestamp(d.dueDate) : new Date(),
            createdOn: dateFromTimestamp(d.createdOn),
            updatedOn: dateFromTimestamp(d.updatedOn),
        }))
        setDocs(parsedData);
    })()}, [setDocs, documents]);
    return docs;
}

export const useDocument = (documentId: string) => {
    const { documents } = useAppContext();
    const [ doc, setDoc ] = useState<Document>();
    useEffect(() => {(async () => {
        const d = await documents.getObject(documentId) as any;
        const parsedData = {
            ...d,
            dueDate: d.dueDate ? dateFromTimestamp(d.dueDate) : new Date(),
            createdOn: dateFromTimestamp(d.createdOn),
            updatedOn: dateFromTimestamp(d.updatedOn),
        };
        setDoc(parsedData as Document);
    })()}, [setDoc, documentId, documents]);
    return doc;
}

export const useNewDocument = () => {
    const { user } = useAuth();
    const { documents } = useAppContext();
    const Timestamp = useTimestamp();
    const [ doc, setDoc ] = useState<Document>({} as Document);
    const [ loading, setLoading ] = useState(false);
    const updateDoc = async (newDoc: Document) => {
        setLoading(true);
        if(doc.id) {
            await documents.updateObject(doc.id, {
                ...newDoc,
                updatedByUser: user.uid,
                updatedBy: user.displayName,
                updatedOn: Timestamp.now(),
            });
            setDoc(newDoc);
        } else {
            const id = await documents.createObject({
                ...newDoc,
                createdByUser: user.uid,
                createdBy: user.displayName,
                createdOn: Timestamp.now(),
            });
            setDoc({ ...newDoc, id });
        }
        setLoading(false);
    };
    return { doc, updateDoc, loading };
}