import InputField from "components/form/InputField";
import { MarginContainer } from "components/layout/Container";
import { useAppContext } from "context/AppContext";
import { Form, Formik } from "formik";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { FC, Fragment, useEffect, useState } from "react";

function downloadJson(obj: any, name: string) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", name);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
};

const SystemConfigCard: FC = () => {
    const ctx = useAppContext();
    const [currentPath, setCurrentPath] = useState<any[]>([]);
    const [currentConfig, setCurrentConfig] = useState({});
    const [activeConfig, setActiveConfig] = useState(ctx.config.activeId ?? 'initial');
    const path = currentPath.join('/');
    const lastPathElement = currentPath[currentPath.length - 1];
    useEffect(() => {
        (async () => {
            setCurrentConfig(await ctx.config.getObject(activeConfig));
        })();
    }, [ctx, setCurrentConfig]);
    const addToPath = (el: any) => {
        setCurrentPath(currentPath.concat([el]));
    }
    const goUpOneLevel = () => {
        setCurrentPath(currentPath.slice(0, -1));
    }
    const updateConfigItem = async (newValue: any) => {
        let configPointer: any = currentConfig;
        for (const p of currentPath.slice(0, -1)) configPointer = configPointer[p];
        configPointer[lastPathElement] = newValue;
        await ctx.config.updateObject(activeConfig, currentConfig);
        setCurrentConfig(await ctx.config.getObject(activeConfig));
        window.alert('Save successful');
    }
    const deleteItem = (itemKey: any) => async () => {
        if (!window.confirm(`Are you sure you want to delete ${path}/${itemKey}?`)) {
            return;
        }
        let configPointer: any = currentConfig;
        for (const p of currentPath.slice(0, -1)) configPointer = configPointer[p];
        configPointer[lastPathElement] = configPointer[lastPathElement].filter((v: any, k: any) => k !== itemKey);
        await ctx.config.updateObject(activeConfig, currentConfig);
        setCurrentConfig(await ctx.config.getObject(activeConfig));
    }
    const deleteField = (itemKey: any) => async () => {
        if (!window.confirm(`Are you sure you want to delete ${path}/${itemKey}?`)) {
            return;
        }
        let configPointer: any = currentConfig;
        for (const p of currentPath.slice(0, -1)) configPointer = configPointer[p];
        delete configPointer[lastPathElement][itemKey];
        await ctx.config.updateObject(activeConfig, currentConfig);
        setCurrentConfig(await ctx.config.getObject(activeConfig));
    }
    const addItem = async () => {
        let configPointer: any = currentConfig;
        for (const p of currentPath.slice(0, -1)) configPointer = configPointer[p];
        configPointer[lastPathElement] = configPointer[lastPathElement].concat([{}]);
        await ctx.config.updateObject(activeConfig, currentConfig);
        setCurrentConfig(await ctx.config.getObject(activeConfig));
    }
    const addField = async () => {
        const field = window.prompt('What field do you want to add to this object?');
        if (!field) return;
        let configPointer: any = currentConfig;
        for (const p of currentPath.slice(0, -1)) configPointer = configPointer[p];
        configPointer[lastPathElement] = { ...configPointer[lastPathElement], [field!!]: '' };
        await ctx.config.updateObject(activeConfig, currentConfig);
        setCurrentConfig(await ctx.config.getObject(activeConfig));
    }

    // resolve which level of the config we are viewing
    let unfoldedConfig: any = currentConfig;
    for (const p of currentPath) unfoldedConfig = unfoldedConfig[p];

    const exportList = () => {
        downloadJson(unfoldedConfig, 'export_config.json');
    };
    const importList = async () => {
        const impStr = window.prompt('Copy and paste your JSON here');
        if(!impStr) return;
        const impObj = JSON.parse(impStr!!);
        await updateConfigItem(impObj);
    }
    // render
    let renderedConfig: any;
    let editableFields: any = undefined;
    let showArrayActions = false;
    let showObjectActions = false;
    if (unfoldedConfig instanceof Array) {
        showArrayActions = true;
        renderedConfig = unfoldedConfig.map((k, ki) => (
            <div key={`button_${path}_${ki}`} className='p-mb-3'>
                <Button label={JSON.stringify(k)} onClick={() => addToPath(ki)} className='p-button-sm p-text-truncate' style={{ maxWidth: '50ch' }} />
                <Button icon='pi pi-minus' iconPos='right' label='Delete' className='p-button-sm p-ml-2 p-button-secondary' onClick={deleteItem(ki)} />
            </div>
        ));
    } else if (typeof unfoldedConfig === 'object') {
        const simpleObject = Object.values(unfoldedConfig).every(v => typeof v !== 'object');
        if (simpleObject) {
            editableFields = unfoldedConfig;
            showObjectActions = true;
        }
        renderedConfig = Object.keys(unfoldedConfig).map(k => (
            <Button key={`button_${path}_${k}`}
                label={k} onClick={() => addToPath(k)} className='p-mb-2 p-button-text' />
        ));
    } else {
        renderedConfig = <p>{unfoldedConfig}</p>;
        if (currentPath[0] !== 'id' && currentPath[0] !== 'isActive') {
            editableFields = { item: unfoldedConfig };
        }
    }
    return <>
        <Card title='System Config Browser' subTitle='View and modify System Config items'>
            {(currentPath.length > 0) && (
                <div className='p-d-flex p-align-center p-mb-3'>
                    <Button icon='pi pi-arrow-up' onClick={goUpOneLevel} className='p-button-sm p-mr-3' />
                    <p style={{ fontSize: '1.1rem' }}>/{path}</p>
                </div>
            )}
            <div className='p-d-flex p-flex-column p-align-stretch'>
                {editableFields ? (
                    <Formik initialValues={editableFields} onSubmit={updateConfigItem}>
                        <Form>
                            {Object.entries(editableFields).map(([key,]) => (
                                <div key={`inputs_${path}_${key}`} className='p-mb-3'>
                                    <InputField fluid key={`input_${path}_${key}`} type='text' name={key} label={`Edit ${key}`} />
                                    {showObjectActions && <Button icon='pi pi-minus' iconPos='right' type='button' label='Delete' className='p-button-sm p-button-secondary' onClick={deleteField(key)} />}
                                </div>
                            ))}
                            <div className='p-d-flex p-flex-row-reverse p-mt-3 p-jc-between '>
                                <Button icon='pi pi-save' type='submit' label='Save' />
                                {showObjectActions && <>
                                    <Button icon='pi pi-plus' type='button' iconPos='right' label='Add Field' className='p-button-sm' onClick={addField} />
                                </>}
                            </div>
                        </Form>
                    </Formik>
                ) : (
                    renderedConfig
                )}
            </div>
            {showArrayActions && (
                <div className='p-d-flex p-align-center p-mt-3'>
                    <Button icon='pi pi-plus' iconPos='right' label='Add Item' className='p-button-sm' onClick={addItem} />
                    <Button icon='pi pi-download' iconPos='right' label='Export List' className='p-button-sm p-button-text p-ml-3' onClick={exportList} />
                    <Button icon='pi pi-upload' iconPos='right' label='Import List' className='p-button-sm p-button-text p-ml-3' onClick={importList} />
                </div>
            )}
        </Card>
    </>;
}

const Page: FC = () => {
    const breadcrumbHome = { icon: 'pi pi-cog', url: '/admin' };
    const breadcrumbModel = [{ label: 'System' }];
    return <>
        <BreadCrumb model={breadcrumbModel} home={breadcrumbHome} />
        <MarginContainer maxWidth='800px'>
            <SystemConfigCard />
        </MarginContainer>
    </>;
}

export default Page;