import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/storage';
import 'firebase/auth';

export type Timestamp = firebase.firestore.Timestamp | any;

export const firebaseConfig = {
    apiKey: "AIzaSyDSiWpgrySxnL7U7JhvlkX18x_bhmsKww8",
    authDomain: "ind-internal.firebaseapp.com",
    databaseURL: "https://ind-internal.firebaseio.com",
    projectId: "ind-internal",
    storageBucket: "ind-internal.appspot.com",
    messagingSenderId: "102962661152",
    appId: "1:102962661152:web:3113db1b5916a5f9b67945",
    measurementId: "G-N3VJ20RWD1",
};
export const firebaseApp = !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();
export const useFirestore = () => firebase.firestore();
export const useAuth = () => firebaseApp.auth();
export const useGoogleAuthProvider = () => new firebase.auth.GoogleAuthProvider();
export const useAnalytics = () => firebaseApp.analytics();
export const useFieldValue = () => firebase.firestore.FieldValue;
export const useTimestamp = () => firebase.firestore.Timestamp;

export function dateFromTimestamp(ts: Timestamp) {
    const seconds = ts._seconds || ts.seconds;
    return new Date(seconds * 1000);
}

export function timestampFromDate(date: Date) {
    return firebase.firestore.Timestamp.fromDate(date);
}

//only load if window object is available (e.g. only on client)
//can be removed to decrease package size
export const firebasePerformance = (typeof window !== 'undefined') && firebaseApp.performance();
