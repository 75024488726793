import { FC, MouseEvent, MouseEventHandler, useRef } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import { Avatar } from 'primereact/avatar';
import { OverlayPanel } from 'primereact/overlaypanel';

import AuthenticationStore from 'context/stores/AuthenticationStore';
import { Button } from 'primereact/button';
import { useListLabels } from 'context/ConfigurationContext';

interface AvatarProps {
    auth: AuthenticationStore,
    onClick?: (e: MouseEvent) => void,
}
const _DynamicAvatar: FC<AvatarProps> = ({
    auth, onClick=undefined
}) => {
    const profileURL = auth?.user?.photoURL;
    if(profileURL) {
        return <Avatar shape='circle' size='normal' image={profileURL} imageAlt='Your Profile Picture' onClick={onClick} />;
    }
    return <Avatar shape='circle' icon='pi pi-user' size='normal' onClick={onClick} />;
}
const DynamicAvatar = observer(_DynamicAvatar);

interface AvatarWidgetProps {
    auth: AuthenticationStore
}
const _AvatarWithPanel: FC<AvatarWidgetProps> = ({ auth }) => {
    const { user, userRole, actualUserRole, logoutUser } = auth;
    const roleLookup = useListLabels('roles');
    const myRole = roleLookup[userRole!!];
    const history = useHistory();
    const overlay = useRef<any>(null);
    const toggleOverlay = (e: MouseEvent) => { overlay.current!.toggle(e); };
    const navigateOCL: (path: string) => MouseEventHandler<HTMLButtonElement> = (path) => (e) => {
        e.preventDefault();
        overlay.current.hide();
        history.push(path);
    };
    return <>
        <DynamicAvatar auth={auth} onClick={toggleOverlay} />
        <OverlayPanel ref={overlay} dismissable>
            <div className='p-my-1' style={{ display: 'block' }}>
                <h4 className='p-mt-0 p-mb-3'>{user?.displayName}</h4>
                <p>{myRole}</p>
                <p className='p-mt-0 p-mb-3 p-text-nowrap p-text-truncate' style={{ width: '25ch' }}>{user?.email}</p>
                <div className='p-mt-0 p-d-flex p-flex-wrap'>
                    <Button icon='pi pi-pencil' className='p-button-rounded p-mr-2'
                        onClick={navigateOCL(`/brother/${user.uid}/edit`)}
                        tooltip='Profile' tooltipOptions={{ position: 'bottom' }} />
                    {actualUserRole==='admin' && (
                        <Button icon='pi pi-cog' className='p-button-rounded p-mr-2'
                            onClick={navigateOCL(`/admin/changeRole`)}
                            tooltip='Change Role' tooltipOptions={{ position: 'bottom' }} />
                    )}
                    <Button icon='pi pi-sign-out' className='p-button-rounded'
                        tooltip='Sign Out' tooltipOptions={{ position: 'bottom' }}
                        onClick={logoutUser} />
                </div>
            </div>
        </OverlayPanel>
    </>;
}
const AvatarWithPanel = observer(_AvatarWithPanel);

const DynamicUserAvatar: FC<AvatarWidgetProps> = ({ auth }) => {
    if (!auth.isLoggedIn) {
        return <DynamicAvatar auth={auth} />;
    }
    return <AvatarWithPanel auth={auth} />;
}

export default observer(DynamicUserAvatar);