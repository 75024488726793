import { FC, useCallback } from 'react';
import { useField } from 'formik';

import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { ToggleButton } from 'primereact/togglebutton';
import { AutoComplete } from 'primereact/autocomplete';
import { ListBox } from 'primereact/listbox';
import { FileUpload } from 'primereact/fileupload';

import { omit } from 'lodash';

interface OptionType {
    label: string,
    value: any,
}
interface GroupedOptionType {
    label: string, code: string,
    items: OptionType[],
}

interface InputFieldProps {
    type?: 'textarea' | 'text' | 'dropdown' | 'checkbox' | 'listbox' | 'file' | 'autocomplete',
    name: string, label: string,
    options?: OptionType[] | GroupedOptionType[],
    suggestions?: OptionType[] | GroupedOptionType[],
    placeholder?: string, disabled?: boolean,
    filter?: boolean, showClear?: boolean,
    fluid?: boolean, col?: boolean,
    uploadHandler?: any,
    completeMethod?: any, autocompleteField?: string,
}

const InputField = ({
    type, name, label, options, suggestions,
    placeholder, disabled, filter, showClear = false,
    fluid = true, col = false, uploadHandler, completeMethod, autocompleteField,
}: InputFieldProps) => {
    const id = `${name}_input`
    const props = {
        id, name, type, disabled, placeholder,
        options, filter,
    };
    const [field, meta] = useField(props);
    const fieldNoBlur = {...omit(field, 'onBlur', 'value')};
    const value = meta.value || '';
    const errorSpan = <span className='p-error'>{meta.error}</span>;
    const fieldError = meta.error ? errorSpan : null;
    const FieldContainer: FC = useCallback(({ children }) => {
        const inner = (
            <div className={`p-field ${col ? 'p-col' : ''}`}>
                {children}
                {fieldError}
            </div>
        );
        if (fluid && !col) {
            return <div className='p-fluid'>{inner}</div>;
        }
        return inner;
    }, [ fluid, col ]);
    if (type === 'text') {
        return (
            <FieldContainer>
                <label htmlFor={id}>{label}</label>
                <InputText value={value} {...props} {...fieldNoBlur}  />
            </FieldContainer>
        );
    }
    if (type === 'textarea') {
        return (
            <FieldContainer>
                <label htmlFor={id}>{label}</label>
                <InputTextarea autoResize value={value} {...props} {...fieldNoBlur} />
            </FieldContainer>
        );
    }
    if (type === 'dropdown') {
        return (
            <FieldContainer>
                <label htmlFor={id}>{label}</label>
                <Dropdown showClear={showClear} value={value} {...props} {...fieldNoBlur} />
            </FieldContainer>
        );
    }
    if (type === 'checkbox') {
        return (
            <FieldContainer>
                <label htmlFor={id}>{label}</label>
                <ToggleButton  checked={value} {...props} {...fieldNoBlur} />
            </FieldContainer>
        );
    }
    if (type === 'listbox') {
        return (
            <FieldContainer>
                <label htmlFor={id}>{label}</label>
                <ListBox value={value} {...props} {...fieldNoBlur} />
            </FieldContainer>
        )
    }
    if (type === 'file') {
        return (
            <FieldContainer>
                <label htmlFor={id}>{label}</label>
                <FileUpload mode='basic' customUpload auto multiple={false} uploadHandler={uploadHandler} {...props} {...fieldNoBlur} />
            </FieldContainer>
        )
    }
    if (type === 'autocomplete') {
        return (
            <FieldContainer>
                <label htmlFor={id}>{label}</label>
                <AutoComplete field={autocompleteField}  suggestions={suggestions} completeMethod={completeMethod} value={value} {...props} {...fieldNoBlur} />
            </FieldContainer>
        )
    }
    return <>
        <p>Input Field not configured correctly</p>
    </>;
}

export default InputField;