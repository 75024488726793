import { connect, FormikContextType } from "formik"
import { FC, PropsWithChildren } from "react";

interface _ConditionalFieldProps {
    condition: (values: any) => boolean,
}
type ConditionalFieldProps = PropsWithChildren<_ConditionalFieldProps>;

const ConditionalField = connect<ConditionalFieldProps>(({
    children, condition, formik,
}) => {
    if(condition(formik?.values)) {
        return <>{children}</>;
    }
    return <></>;
})

export default ConditionalField;