import { useState, useEffect } from 'react';

import { useAppContext } from './AppContext';

interface FileInformation {
    bucket: string,
    contentType: string,
    fullPath: string,
    md5Hash: string,
    name: string,
    size: number,
    timeCreated: string,
    // type: 'file',
    updated: string,
}

export const useDownloadURL = (filePath?: string) => {
    const { storage } = useAppContext();
    const [fileURL, setFileURL] = useState<string>('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            if (filePath) {
                setLoading(true);
                const downloadURL = await storage.getDownloadURL(filePath);
                setFileURL(downloadURL);
                setLoading(false);
            }
        })()
    }, [filePath, setFileURL, setLoading, storage]);
    return { fileURL, loading };
}

export const useFileInfo = (filePath?: string) => {
    const { storage } = useAppContext();
    const [fileInfo, setFileInfo] = useState<FileInformation|null>(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        (async () => {
            if (filePath) {
                setLoading(true);
                const info = await storage.getFileInformation(filePath) as FileInformation;
                setFileInfo(info);
                setLoading(false);
            }
        })()
    }, [filePath, setFileInfo, setLoading, storage]);
    return { fileInfo, loading };
}