import { useMemo } from 'react';

import algoliasearch from 'algoliasearch/lite';

export const client = algoliasearch('20YNACIFXC', '510eecd74280bd055056243d91eada58');

export const useAlgoliaIndex = (indexName: string) => {
    return useMemo(() => {
        return client.initIndex(indexName);
    }, [indexName]);
};