import { makeObservable } from 'mobx';

import { firebaseApp } from 'config/firebase';
import FirestoreCollectionService from 'services/FirestoreCollectionService';

const serviceConfig = {
    db: firebaseApp.firestore(),
    collectionName: 'documents',
    objectSortColumn: 'createdOn',
    objectSortDirection: 'desc',
};
const storeConfig = {
    store: false,
    objectCollection: false,
};

class DocumentStore extends FirestoreCollectionService {
    store = null;

    constructor(store) {
        super(serviceConfig);
        makeObservable(this, storeConfig);
        this.store = store;
    }

    objectCollection = (view='default') => {
        let collectionRef = this.db.collection(this.collectionName);
        // if (view==='user' && this.store.auth.user!==null) {
        //     collectionRef = collectionRef.where('createdByUser','==',this.store.auth.user.uid);
        // }
        return collectionRef;
    };

    getRecentDocuments = () => {
        return this.getRecentObjects()
    };
}

export default DocumentStore;