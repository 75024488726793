import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { Messages } from 'primereact/messages';

import { useAuth } from 'context/AuthContext';
import { useBrother } from 'context/BrotherContext';
import { MarginContainer } from 'components/layout/Container';

const DashboardAlerts: FC = () => {
    const { user } = useAuth();
    const { brother, loaded } = useBrother(user.uid);
    const messagesRef = useRef<any>(null);
    useEffect(() => {
        if(!loaded) {
            return
        }

        const showMissingBrotherProfileAlert = Object.keys(brother).length === 0;
        const showInProgressBrotherProfileAlert = Object.keys(brother).length > 0 && (brother?.state === undefined || brother?.state === '');

        const alerts = [];
        if (showMissingBrotherProfileAlert) {
            alerts.push({
                severity: 'error', sticky: true, closable: false, content: (
                    <Link to='/brother/create'>
                        <p>
                            You haven't yet created a Brother profile yet. This is required in
                            order to use Brother Search and other functions. Click on this message
                            to create your profile now.
                        </p>
                    </Link>
                )
            });
        }
        if (showInProgressBrotherProfileAlert) {
            alerts.push({
                severity: 'warn', sticky: true, closable: false, content: (
                    <Link to='/brother/create'>
                        <p>
                            You have an incomplete Brother profile. Click on this message to
                            continue with creation.
                        </p>
                    </Link>
                )
            });
        }
        messagesRef.current?.replace(alerts);
    }, [brother, loaded]);
    return (
        <MarginContainer>
            <Messages ref={messagesRef} />
        </MarginContainer>
    );
}

export default DashboardAlerts