import { FC, Children, isValidElement, useMemo, ReactElement, useContext, createContext } from 'react';

import { useWorkflowController, defaultWorkflowContext, WorkflowProps, WorkflowContextProps } from 'hooks/workflow/WorkflowHooks';

export interface WorkflowStateProps {
    state: string,
}

export const WorkflowContext = createContext<WorkflowContextProps>(defaultWorkflowContext);
export const withWorkflowContext = (Component: any) => (props: any) => (
    <WorkflowContext.Consumer>
        {context => <Component context={context} {...props} />}
    </WorkflowContext.Consumer>
);
export const useWorkflowContext = () => useContext<WorkflowContextProps>(WorkflowContext);

const unknownWorkflowState = <p>Unknown Workflow State</p>;
const COMMON_STATE = '';

export const SimpleWorkflowState: FC<WorkflowStateProps> = ({ children }) => <>{children}</>;
export const CommonWorkflowState: FC = ({ children }) => <SimpleWorkflowState state={COMMON_STATE}>{children}</SimpleWorkflowState>;

const Workflow: FC<WorkflowProps> = ({ children, initialState, validateTransaction }) => {
    const workflow = useWorkflowController({ initialState, validateTransaction });
    /**
     * Build a Map between state codes and state elements.
     */
    const stateCodeMap = useMemo(() => {
        const _stateMap: Map<string, ReactElement<WorkflowStateProps>[]> = new Map();
        Children.forEach(children, (child: any) => {
            if (isValidElement<WorkflowStateProps>(child)) {
                const childCode: string = child.props.state ?? '';
                if (_stateMap.has(childCode)) {
                    const elemList = _stateMap.get(childCode);
                    elemList?.push(child);
                } else {
                    _stateMap.set(childCode, [child]);
                }
            }
        });
        return _stateMap;
    }, [children]);
    /**
     * Build a list of states that should be shown depending on the current workflow state.
     * Always show COMMON_STATE elements.
     */
    const displayStates = useMemo(() => {
        const _displayStates = [];
        if (stateCodeMap.has(COMMON_STATE)) {
            const commonStates = stateCodeMap.get(COMMON_STATE)!;
            _displayStates.push(...commonStates);
        }
        if (workflow.state !== COMMON_STATE) {
            if (stateCodeMap.has(workflow.state)) {
                const selectedStates = stateCodeMap.get(workflow.state)!;
                _displayStates.push(...selectedStates);
            } else {
                _displayStates.push(unknownWorkflowState)
            }
        }
        return _displayStates;
    }, [workflow.state, stateCodeMap]);
    return (
        <WorkflowContext.Provider value={workflow}>
            {displayStates}
        </WorkflowContext.Provider>
    );
}

export default Workflow;