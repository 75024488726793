import { FC, useEffect } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import PrimeReact from 'primereact/api';

import { AppContextProvider, withContext } from 'context/AppContext';

import AppLoadingLayer from 'components/loading/AppLoadingLayer';
import AppMenu from 'components/menu/AppMenu';
import AppFooter from 'components/footer/AppFooter';

import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import AdminSwitch from 'pages/admin/AdminSwitch';
import BrotherSwitch from 'pages/brother/BrotherSwitch';
import DocumentSwitch from 'pages/document/DocumentSwitch';
import CommunityDashboard from 'pages/community/CommunityDashboard';

import './App.scss';

PrimeReact.ripple = true;

interface SPAProps {
  location?: any,
  history?: any,
  context?: any,
}
const App: FC<SPAProps> = ({
  location, history, context
}) => {
  // Inject history into global context
  useEffect(() => {
    context.history = history;
  }, [context, history])
  return (
    <div className='App'>
      <header>
        <AppMenu />
      </header>
      <main>
        <AppLoadingLayer auth={context.auth}>
          <Switch location={location}>
            <Route exact path='/' component={Dashboard} />
            <Route exact path='/login' component={Login} />
            <Route path='/admin**' component={AdminSwitch} />
            <Route path='/brother**' component={BrotherSwitch} />
            <Route path='/document**' component={DocumentSwitch} />
            <Route path='/community' component={CommunityDashboard} />
          </Switch>
        </AppLoadingLayer>
      </main>
      <footer>
        <AppFooter />
      </footer>
    </div>
  );
};

const AppWithRouter = withRouter(withContext(App));

const SinglePageApp = () => (
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>
)

export default SinglePageApp;
