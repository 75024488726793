import firebase from 'firebase/app';
import 'firebase/auth';
import { makeAutoObservable } from 'mobx';

import { firebaseApp } from 'config/firebase';
import { toMap } from 'util/functional/ListUtil';

const storeConfig = {
    store: false,
    handleAuthResponse: false,
    signInWithGoogle: false,
    logoutUser: false,
};

class AuthenticationStore {
    user = null;
    userProviderData = {};
    userClaims = null;
    userRole = null;
    actualUserRole = null;
    idToken = null;
    isLoggedIn = false;
    isLoaded = false;
    isAdmin = false;
    store = null;

    constructor(store) {
        makeAutoObservable(this, storeConfig);
        this.store = store;
        firebaseApp.auth().onAuthStateChanged(this.handleAuthResponse);
    }

    setUser = (user = null, userClaims = null) => {
        this.user = user;
        this.userClaims = userClaims;
        if (userClaims && userClaims.role) {
            this.actualUserRole = userClaims.role;
            this.setUserRole(userClaims.role);
        }
        this.isLoggedIn = !!user;
        if (user) {
            this.userProviderData = toMap(user.providerData, (u => u.providerId));
        } else {
            this.userProviderData = {};
        }
    };

    setToken = (token) => {
        this.idToken = token;
    }

    setUserRole = (role) => {
        this.userRole = role;
        this.isAdmin = role === 'admin';
    }

    handleAuthResponse = async (user) => {
        if (user) {
            // get latest config
            await this.store.config.initialize();
            // set user details
            const tokenResult = await user.getIdTokenResult();
            this.setUser(user, tokenResult.claims);
            this.setToken(tokenResult.token);
            // get latest mattermost config
            await this.store.mattermost.initialize();
        } else if (this.isLoggedIn) {
            this.setUser();
        }
        this.completeLoading();
    };

    completeLoading = () => {
        this.isLoaded = true;
    };

    signInWithGoogle = async () => {
        try {
            const googleProvider = new firebase.auth.GoogleAuthProvider();
            await firebaseApp.auth().signInWithPopup(googleProvider);
            if (this.store.history.location.pathname === '/login') {
                this.store.changeRoute('/');
            }
        } catch (err) {
            if (err.code !== 'auth/popup-closed-by-user') {
                console.warn(err);
            }
        }
    };

    signInWithEmailPassword = async (email, password) => {
        try {
            await firebaseApp.auth().signInWithEmailAndPassword(email, password);
            if (this.store.history.location.pathname === '/login') {
                this.store.changeRoute('/');
            }
        } catch (err) {
            console.warn(err);
        }
    };

    logoutUser = async () => {
        try {
            this.store.changeRoute('/login');
            await firebaseApp.auth().signOut();
        } catch (err) {
            console.warn(err);
        }
    };
}

export default AuthenticationStore;