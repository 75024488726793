import { Card } from "primereact/card";
import { Steps } from "primereact/steps";

import Workflow, { SimpleWorkflowState } from "workflows/components/Workflow";
import { ActionButton, ActionFormButton } from "workflows/components/Actions";
import { MarginContainer } from "components/layout/Container";
import { useDocumentDeleteController } from "controllers/document/DocumentDeleteController";
import { FC } from "react";
import { FileInfoCard } from "./DocumentView";

const brotherDeleteWorkflowModel = [
    { label: 'Confirm' },
    { label: 'Status' },
];

interface DocumentDeleteProps {
    documentId: string
}
const DocumentDelete: FC<DocumentDeleteProps> = ({ documentId }) => {
    const { doc, deleted, loading, DeleteDocumentForm } = useDocumentDeleteController(documentId);
    return <>
        <MarginContainer maxWidth='800px'>
            <Workflow initialState='start'>
                <SimpleWorkflowState state='start'>
                    <Card title='Confirm Action'>
                        <Steps model={brotherDeleteWorkflowModel} activeIndex={0} />
                        <div className='p-px-3'>
                            <p className='p-my-4'>
                                Are you sure you want to delete this document?
                            </p>
                            <p className='p-my-3'>
                                <FileInfoCard document={doc}/>
                            </p>
                            <DeleteDocumentForm>
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionFormButton to='end' label='Delete' />
                                    <ActionButton urlType='path' to={`/document/${documentId}`} label='View' className='p-button-text' />
                                </div>
                            </DeleteDocumentForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
                <SimpleWorkflowState state='end'>
                    <Card title='Success!'>
                        <Steps model={brotherDeleteWorkflowModel} activeIndex={1} />
                        <div className='p-px-3'>
                            <p>
                                This document has been successfully deleted.
                            </p>
                            <DeleteDocumentForm>
                                <div className='p-d-flex p-jc-around p-flex-row-reverse'>
                                    <ActionButton urlType='path' to={`/`} label='Go Home' />
                                </div>
                            </DeleteDocumentForm>
                        </div>
                    </Card>
                </SimpleWorkflowState>
            </Workflow>
        </MarginContainer>
    </>
}

export default DocumentDelete;