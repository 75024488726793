import { useState, useEffect } from 'react';

import { useAppContext } from './AppContext';

export interface MattermostUser {
    id: string,
    username: string, email: string,
    nickname?: string, first_name?: string, last_name?: string,
    create_at: number, update_at: number, roles: string,
    position?: string,
}

export interface MattermostTeam {
    id: string,
    display_name: string, name: string, description: string,
    create_at: number, update_at: number,
}

export const useMattermost = () => {
    const { mattermost } = useAppContext();
    return mattermost;
}

export const useMattermostUser = () => {
    const mattermost = useMattermost();
    const [user, setUser] = useState<MattermostUser>();
    const [loading, setLoading] = useState(false);
    const [error,] = useState('');
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = (await (await mattermost.apiCall('/users/me')).json());
                setUser(res);
            } catch(err) {
                console.warn(err.toString());
            }
            setLoading(false);
        })();
    }, [mattermost, setLoading, setUser]);
    return { user, loading, error };
}

export const useMattermostTeams = () => {
    const mattermost = useMattermost();
    const [teams, setTeams] = useState<MattermostTeam[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const res = (await (await mattermost.apiCall('/users/me/teams')).json());
                setTeams(res);
            } catch(err) {
                setError(err.toString());
            }
            setLoading(false);
        })();
    }, [mattermost, setLoading, setTeams]);
    return { teams, loading, error };
}