import { FC } from 'react';

import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { withContext, AppContext } from 'context/AppContext';
import { SingleColumnContainer } from 'components/layout/Container';

interface LoginPageProps {
    context: AppContext
}

const LoginPage: FC<LoginPageProps> = ({ context }) => {
    const googleSignInOCL = context.auth.signInWithGoogle;
    return (
        <SingleColumnContainer>
            <Card title='Fraternity Sign In'
                subTitle='Please sign-in using your Iota Nu Delta Google Account'
                className='p-text-center p-pt-2' style={{ backgroundColor: 'var(--surface-50)' }}>
                <Button label='Sign in with Google' onClick={googleSignInOCL}
                    icon='pi pi-google' iconPos='left'
                    className='p-button-rounded' />
            </Card>
        </SingleColumnContainer>
    );
}

export default withContext(LoginPage);